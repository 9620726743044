import {Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {DwSelectComponent} from 'ng-quicksilver';
import {of, Observable} from 'rxjs';
import {DOWN_ARROW, ENTER, TAB, UP_ARROW} from '@angular/cdk/keycodes';

@Component({
  selector: 'app-cell-select-editor',
  templateUrl: './cell-select-editor.component.html',
  styleUrls: ['./cell-select-editor.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CellSelectEditorComponent implements  OnDestroy {

  formGroup: FormGroup;
  fParam: any;
  private colId: string;
  private rowId: number;
  private rowIndex: number;
  initValue: any;
  autoFocus = false;
  // label;
  // 下拉清单
  options;
  // // 禁用属性表达式
  // disabledExpr: Function;

  dwShowSearch: boolean;
  dwAllowClear: boolean;
  // dwDisabled: boolean;
  // @ViewChild('cellInput') cellInput: ElementRef;
  @ViewChild('cellInput') cellInput: DwSelectComponent;
 
  // gets called once after the editor is created
  agInit(params: any): void {
    this.fParam = params;
    this.colId = params.column.colId;
    this.rowId = params.node.id;

    // const rowFromGroup = params.context.serviceObj.rowCachedFormGroup;
    // this.formGroup = rowFromGroup;
    this.rowIndex = params.context.serviceObj.indexMap.get(this.rowId);
    const cellFromGroup = ((params.context.myFormArray as FormArray).at(this.rowIndex) as FormGroup);
    this.formGroup = cellFromGroup;

    // this.disabledExpr = params.disabledExpr || ((params: any) => { return false; });

    this.options = Array.isArray(params.values)
      ? of(params.values)
      : params.values;
    this.dwShowSearch = true;
    this.dwAllowClear = !!params.dwAllowClear;

    // if (this.disabledExpr) {
    //   this.formGroup.get(this.colId).setValue(params.value);
    //   if (Array.isArray(this.options)) {
    //     this.label = this.findValue(this.options);
    //   } else {
    //     const observable: Observable<any> = this.options;
    //     observable.subscribe(data => {
    //       this.label = this.findValue(data);
    //     });
    //   }
    // }
  }

  onKeyDown(event): void {
    // if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38
    //   || event.keyCode === 39 || event.keyCode === 40) {
    //   event.stopPropagation();
    // }
    // if (event.keyCode === ENTER || event.keyCode === TAB) {
    //   this.cellInput.blur();
    // }
    var isNavigationKey = event.keyCode === 38 || event.keyCode === 40;
    if (isNavigationKey) {
        event.stopPropagation();
    }

  }

  // findValue(arr: Array<any>): string {
  //   const matchedItem = arr.find(item => item.value === this.formGroup.get(this.colId).value);
  //   return matchedItem ? matchedItem.label : '';
  // }

  // Gets called once after GUI is attached to DOM.
  // Useful if you want to focus or highlight a component
  // (this is not possible when the element is not attached)
  afterGuiAttached(): void {
    if (this.fParam.cellStartedEdit) {
      // this.cellInput.nativeElement.focus();
      this.cellInput.focus();
    }
  }
  onChange(): any {
    if (this.formGroup.get(this.colId).value === this.initValue) {return}
    if (this.fParam && this.fParam.onChange) {
      this.fParam.onChange({
        gridId: this.fParam.context.gridId || '',
        value: this.getValue(),
        rowIndex: this.rowIndex,
        id: this.rowId,
        colId: this.colId,
        controlName: ''
      });
    }
    // this.cellInput.nativeElement.focus();
    // this.cellInput.focus();
  }

  onOpen(xEvent): any {
    // console.log(xEvent);
    // if (!xEvent) {
    //   this.autoFocus = true;
    //   this.cellInput.focus();
    //   // this.cellInput.onTouched();
    //   // this.cellInput.onClick();
    //   // this.cellInput.inputElement.nativeElement.blur();
    // }
  }

  // closeDropDown(xEvent): any {
  //   console.log(xEvent);
  // }
  // Should return the final value to the grid, the result of the editing
  getValue(): any {
    return this.formGroup.get(this.colId).value;
  }

  focusIn(): void {
    // this.cellInput.nativeElement.focus();
    this.initValue = this.formGroup.get(this.colId).value;
    this.cellInput.focus();
  }

  // If doing full row edit, then gets called when tabbing out of the cell.
  focusOut(): void {
    // this.cellInput.nativeElement.blur();
    // this.cellInput.blur();
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd(): boolean {
    // return this.validationMessages.length > 0;
    return false;
  }

  // Gets called once after initialised.
  // If you return true, the editor will appear in a popup
  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void {}
}

