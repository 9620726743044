/**
 * todo
 * 1. 增加参数控制是否分页，默认分页
 * 2. 样式优化
 */

import { Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation,ElementRef } from '@angular/core';
import { DwModalRef } from 'ng-quicksilver';
import { TranslateService } from '@ngx-translate/core';
import { GridApi } from 'ag-grid-community';
// import { DwRoutingMessageService } from '@webdpt/framework';
import { DwMessageService } from 'ng-quicksilver';
import { DwHttpClient } from '../../../../../../projects/webdpt/framework/http';
import { moreComCondComponent } from './more-comcond';
import { Observable } from 'rxjs';
import { FxAgGridSelectCellRendererComponent } from '../../ag-grid/fx-ag-grid-select-cell-renderer/fx-ag-grid-select-cell-renderer.component';
import { FX_AG_GRID_FW_COMPONENTS } from '../../fx-ag-grid-editors-module';
import { FxAgCheckboxRendererComponent } from '../../ag-grid/fx-ag-checkbox-renderer.component';
import { endTimeRange } from '@angular/core/src/profile/wtf_impl';
import { KeysPipe } from '../fx-address-picker/ngx-address/components/pipes/keys.pipe';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';

export class FxSearchModalparam {
  title?: string = '开窗查询';
  selected?: any = [];
  dataSet: any;
  columnDefs: any;
  mutilSelected?: boolean = false;
  postParam?: any;  // 查询请求参数
  isBackendPaging?: any;  // 是否后端分页true-后端分页，false-前端分页
  pageCount?: any; // 总共多少页
  rowCount?: any;  // 总共多少笔资料
  searchFn?: any;  // 查询方法
  searchlableList?: any; // 可查询字段下拉 
  okFn?: any;
  cancelFn?: any;
  isCustomWindow: any; // 是否是自定义开窗
  isRequired?: boolean = false; // 是否必须选中资料
  widthMu?: any = 0.5; // 开窗大小占页面比例 建议0.4 -0.8
  programId?: any;
  isExpand?: any;
  selfStyle?: any;
}

/**
 * 开窗设置
 */
export class FxSearchModelConfig {
  aaoent: string;         // 企业编号
  aao001: string;         // 开窗编号
  aao001_n0: string;      // 开窗名称1
  aao001_n1: string;      // 开窗名称1
  aao002: string;         // 自定义开窗(T/F)
  aao003: string;         // 分页(T/F)
  aap_list: Array<FxSearchModelColumn>;  // 栏位定义
}

/**
 * 开窗显示栏位设置
 */
export class FxSearchModelColumn {
  aap001: string;         // 开窗编号
  aapseq: string;         // 字段位次
  aap002: string;         // 字段编号
  aap002_n0: string;      // 字段说明
  aap002_n1: string;      // 字段说明
  aap003: string;         // 显示类型 (1.Edit 2.Date 3.Select 4.Checkbox:Y/N)
  aap004:string;          //系统分类
  optionsaap004$: Observable<any>; // 系统分类 类型
}

/**
 * 设计要求
 * icon 22px
 * title 16px
 * content 14px
 */
@Component({
  selector: 'fx-search-modal-component',
  templateUrl: './fx-search-modal.component.html',
  styleUrls: [
    './fx-search-modal.component.less'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class FxSearchModalComponent implements OnInit {
  @Input() title: string;
  @Input() dataSet: Array<any>;
  @Input() selected: Array<any>;
  @Input() mutilSelected: boolean;
  // ag-grid 栏位配置
  @Input() columnDefs: any;
  // 数据分页部分
  @Input() postParam: any;   // 设定开窗分页添加请求参数
  @Input() searchFn: any;      // 查询的方法
  @Input() isCustomWindow: any;      // 是否自定义开窗
  @Input() aap003: any;
  @Input() isBackendPaging: any;   // 设定前端分页还是后端分页-true-后端分页，false-前端分页
  @Input() pageCount: number;     // 记录当前多少页
  @Input() rowCount: number;      // 记录当前资料总笔数
  @Input() searchlableList: Array<any>;      // 记录当前资料总笔数
  @Input() isRequired: boolean;// 判断是否必须选中资料
  public searchVal = true;
  public selectedList = []; // 记录后端分页选中的资料
  public keys = []; // 记录页面显示的栏位

  public selectedItem: Array<any> = [];
  public isHeight = '325px';
  public isParm = true;
  public isFull = false;
  context = {};

  public isMoreConShow = false; // 进阶查询是否展开，true-展开，false-关闭，默认false关闭
  inBounds = true;
  edge = {
    top: true,
    bottom: true,
    left: true,
    right: true
  };
  @ViewChild('layout') layout: ElementRef;
  @ViewChild('moreComcond') private searchMore: moreComCondComponent; // 公共的查询组件

  COL_SINGLE_SELECT = {
    headerName: '',
    width: 55,
    headerCheckboxSelection: false, // 标题添加checkbox
    headerCheckboxSelectionFilteredOnly: true, // 全选只勾选过滤部分
    checkboxSelection: true
  };

  COL_MUTIL_SELECT = {
    headerName: '',
    width: 55,
    headerCheckboxSelection: true, // 标题添加checkbox
    headerCheckboxSelectionFilteredOnly: true, // 全选只勾选过滤部分
    checkboxSelection: true
  };

  // 数据集
  _displyData: Array<any> = [];
  // 搜索字串
  _searchText: string = '';
  // ag-grid 栏位配置
  _defaultColDef: any = {};
  _columnDefs: Array<any> = [];
  // ag-grid renderer 配置
  // _components = {};
  // ag-grid API
  _gridApi: GridApi;
  // ag-grid-column API
  gridColumnApi: any;
  // 行选模式
  // _rowSelection: string = 'multiple';
  _rowSelection: string = 'single';
  // ag-grid 页码
  _pageSize: number = 10;
  _pageNumber: number = 1;
  _rowCount: number = 1;
  //每页显示笔数
  pageSizeOption: Array<number> = [10, 50, 100, 150];

  //页面渲染函数
  public _components = {

  }

  frameworkComponents = {   // Ag-grid 组件
    // ...FX_AG_GRID_FW_COMPONENTS
    fxGridSelectRenderer: FxAgGridSelectCellRendererComponent,
    fxCheckboxCellRenderer: FxAgCheckboxRendererComponent,
  };
 
  
  constructor(
    private modal: DwModalRef,
    public dwMessage: DwMessageService,
    private translateService: TranslateService, 
    public http: DwHttpClient,
    public userinfo: FxUserInfoService,
    ) {
  }

  ngOnInit(): void {
    if (this.isBackendPaging) {
      this._rowCount = this.rowCount;
    } else {
      this._rowCount = this.dataSet.length;
    }

    // @ts-ignore
    this._rowSelection = this.modal.dwComponentParams.mutilSelected ? 'multiple' : 'single';
    this._defaultColDef = {
      width: 100,
      editable: false
    };
    let colDefs = [{
      headerName: '',
      // field:'ischecked',
      width: 55,
      pinned: 'left',
      // @ts-ignore
      headerCheckboxSelection: this.modal.dwComponentParams.mutilSelected, // 标题添加checkbox
      headerCheckboxSelectionFilteredOnly: true, // 全选只勾选过滤部分
      checkboxSelection: true,
      suppressSizeToFit: true,
      cellClass: this._rowSelection == 'single'? 'fx_single_select' : '',
      cellRenderer: (params) => {
        if (params.colDef.cellClass == 'fx_single_select') {
          var span = document.createElement('span');
          span.className = 'ag-icon-checkbox-checked-inner';
          params.eGridCell.children[0].children[0].children[0].append(span);
        }
      }
    }];
    // @ts-ignore
    const pColDefs = this.modal.dwComponentParams.columnDefs;
    if (pColDefs && pColDefs.length > 0) {
      colDefs = colDefs.concat(pColDefs);
      colDefs[colDefs.length - 1].width = undefined;
    }
    this._columnDefs = colDefs;
    // // 首次查询没有资料则默认展开进阶查询提供搜索
    // if (this.dataSet.length == 0) {
    //   this.isMoreConShow = true;
    // }
    this.context = {
      componentParent: this,
      id: 'masterGrid'          //  给ag-Grid指定ID
    };
    if (this.dataSet && this.dataSet.length <= 10) {
      this.setGridData(this.dataSet);
    } else {
      this.setGridData(this.dataSet.filter((item, index) => {return index < 10}));
    }
    // this.setDataSelected();
    
    this._columnDefs.forEach(item => {
      if (item.field) {
        this.keys.push(item.field);
      }
    });

    const paramPageSize = this.userinfo.getEntParamById('c_E007');
      const options = paramPageSize.split(',').map(size => Number.parseInt(size, 10));
      if (Array.isArray(options) && (options.length > 0)) {
        this.pageSizeOption = options;
      }
      this._pageSize = this.pageSizeOption[0];
  }

  setDataSelected(): void {
    setTimeout(() => {
      // 设置已选择的数据为选中状态
      if (this.selected && this.selected.length) {
        // 获取key值
        const selectedKeys = Object.keys(this.selected[0]);
        if (selectedKeys.length === 2) {
          this._gridApi.forEachNode((node: any, index1: number) => {
            this.selected.forEach(function (value) {
              if (node.data[selectedKeys[0]] === value[selectedKeys[0]] && node.data[selectedKeys[1]] === value[selectedKeys[1]]) {
                node.setSelected(true);
              }
            });
          });
        } else {
          if (selectedKeys.length >= 1 && selectedKeys.length !== 2) {
            this._gridApi.forEachNode((node: any, index1: number) => {
              this.selected.forEach(function (value) {
                if (node.data[selectedKeys[0]] === value[selectedKeys[0]]) {
                  node.setSelected(true);
                }
              });
            });
          }
        }
      }
    }, 300);
  }
  setGridData(data: any): void {
    this._displyData = data;
    // if (this.isBackendPaging) { // 
    //   this._rowCount = this.rowCount;
    // } else {
    //   // this._rowCount = this._displyData.length;
    //   this._rowCount = this.dataSet.length;
    // }
    if (this._rowSelection == 'single') {
      this.selectedList = [];
    }
    this.setDataSelected();
    this.setCheckedStus(); // 设置选中的状态
    this.setAutoSize();
  }

  search(): void {
    if (this.isBackendPaging) { // 后端分页
      this.searchMore.getsearchParam();
      this._pageNumber = 1;
      this.searchFn(this.postParam.modalID, this.postParam.postParam, this.postParam.searchType, this.postParam.isChkAuth, this.postParam.no_translation, this._pageNumber, this._pageSize, this._searchText, '', this.searchMore.searchParam.condition).subscribe((config) => {   
        if (config.data && config.data.datas && config.data.datas.length) {
          this._rowCount = config.data.rowCount;
          this.setGridData(config.data.datas);
          // this.setDataSelected();
        } else {
          this._rowCount = 0;
          this.setGridData([]);          
        }
        this.isMoreConShow = false; // 查询完隐藏进阶查询
        // this.setCheckedStus(); // 设置选中的状态
      });
    } else { // 前端分页
       // 开窗模糊筛选，只过滤画面显示的字段，其他后端返回的字段不做筛选
       const dataSet_show_w = [];
       for(var i=0;i<this.dataSet.length;i++){
         // console.log(this.dataSet[i]);
         const dataSet_show_n = {};
         for(var j=1;j<this._columnDefs.length;j++){
           // console.log(this._columnDefs[j].field);
           Object.assign(dataSet_show_n,{[this._columnDefs[j].field]:this.dataSet[i][this._columnDefs[j].field]})
           // console.log(dataSet_show_n);
         }
         this.dataSet[i].viewShowField = dataSet_show_n;
        //  dataSet_show_w.push(this.dataSet[i])
       }
       if (this._searchText) {
         const filterList = this.dataSet.filter((data, index) => {
          //  this.setGridData(dataSet_show_w.filter(data => {
           const strData = data.viewShowField ? JSON.stringify(data.viewShowField) : '';
          return strData.includes(this._searchText);
        });
        this._rowCount = filterList.length;
        this._pageNumber = 1;
        this.setGridData(filterList.filter((item, index) => {
          return index >= 0 && index < (Number(this._pageSize) * Number(this._pageNumber));
        }));
       } else {
        this._pageNumber = 1;
        this._rowCount = this.dataSet.length;
        this.setGridData(this.dataSet);
       }
      // this.setDataSelected();
    }
  }

  // 分页勾选--start
  // 重新查询后设置之前选中的数据
  setCheckedStus(): void {
    setTimeout(() => {
      this._gridApi.forEachNode(node => {
        // 判断此node是否需要设置选中     
        const idx = this.selectedList.findIndex(selItem => {
          // 以页面显示的栏位判断数据是否相同
          const sameKeys = this.keys.filter(key => {
            return selItem[key] === node.data[key];
          });
          if (sameKeys.length === this.keys.length) {
            return true;
          } else {
            return false;
          }
        });
        if (idx !== -1) {
          node.setSelected(true);
        }
      });
    }, 200);
  }

  // grid勾选状态改变
  selectionChanged(params: any): void {   
    if (this._rowSelection == 'multiple') {
      this._gridApi.forEachNode(node => {
        // 判断当前node是否已存储;
        const idx = this.selectedList.findIndex(selItem => {
          const sameKeys = this.keys.filter(key => {
            return selItem[key] === node.data[key];
          });
          if (sameKeys.length === this.keys.length) {
            return true;
          } else {
            return false;
          }
        }); 
        if (idx === -1) { // 未存储：如果是选中状态则添加进去，否则不做处理
          if (node['selected']) {
            this.selectedList.push(JSON.parse(JSON.stringify(node.data)));
          }
        } else {// 已存储：如果是选中状态则不做处理，否则删除
          if (!node['selected']) {
            this.selectedList.splice(idx, 1);
          } 
        }
      })
    } else {
      const selNodeDatas = this._gridApi.getSelectedRows();
      this.selectedList = selNodeDatas.length == 0 ? [] : selNodeDatas;
    }
  }
  // 分页勾选--end

  // 清空查询参数按钮
  cleanSearchParam(): void {
    this._searchText = '';
    this.searchMore.searchVal.forEach(item => {
      item.value = '';
    });
    this._pageNumber = 1;
    this._rowCount = this.dataSet.length;
    this.setGridData(this.dataSet);
  }

  // 自适应
  setAutoSize () { 
    setTimeout(() => {
      var columnList = this.gridColumnApi.getAllDisplayedColumns(); // 获取当前显示的列  
      columnList = columnList.filter((col) => {
        return col.colDef.checkboxSelection != true;
      });
      this.gridColumnApi.autoSizeColumns(columnList)// 设置当前显示的列为自动宽度fx-search-modal-component 
      var agViewport = document.querySelector('fx-search-modal-component .ag-body-viewport');
      var agContainer = document.querySelector('fx-search-modal-component .ag-body-viewport .ag-body-container');
      if (agViewport && agContainer && agViewport.clientWidth > agContainer.clientWidth) {
        this._gridApi.sizeColumnsToFit();
      }
    }, 150);   
  }

  // gridAPI赋值
  onGridReady(params: any): void {
    this._gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this._gridApi.sizeColumnsToFit();
    this.setAutoSize();
  }

  // // grid 选择事件
  // selectionChanged(params: any): void {
  //   // todo
  //   // console.log(this._displyData);
  // }

  debug(): void {
    // const selectedRows = this._gridApi.getSelectedRows();
    // console.warn(selectedRows);
    this._gridApi.sizeColumnsToFit();
  }

  onPageIndexChange(pageIndex: number): void {
    this._pageNumber = pageIndex;
    if (this._pageNumber > 0) {
      if (this.isBackendPaging) {
        this.searchFn(this.postParam.modalID, this.postParam.postParam, this.postParam.searchType, this.postParam.isChkAuth, this.postParam.no_translation, this._pageNumber, this._pageSize, this._searchText, '', this.searchMore.searchParam.condition).subscribe((config) => {
          if (config.data && config.data.datas && config.data.datas.length) {
            this._rowCount = config.data.rowCount;
            this.setGridData(config.data.datas);
            // this.setDataSelected();
          } else {
            this._rowCount = 0;
            this.setGridData([]);
          }
          this.isMoreConShow = false; // 查询完隐藏进阶查询
          // this.setCheckedStus(); // 设置选中的状态
        });
      } else {
        if (this._searchText) {
          const filterList = this.dataSet.filter((data, index) => {
            const strData = data.viewShowField ? JSON.stringify(data.viewShowField) : '';
           return strData.includes(this._searchText);
         });
         this._rowCount = filterList.length;
         this.setGridData(filterList.filter((item, index) => {
          return index >= (Number(this._pageSize) * (Number(this._pageNumber) - 1)) && index < (Number(this._pageSize) * Number(this._pageNumber));
         }));
        } else {
          this.setGridData(this.dataSet.filter((data, index) => {
            return index >= (Number(this._pageSize) * (Number(this._pageNumber) - 1)) && index < (Number(this._pageSize) * Number(this._pageNumber));
          }));
        }
        // this._gridApi.paginationGoToPage(this._pageNumber - 1);
        
        // this.setDataSelected();
      }
    }
  }

  onPageSizeChange(): void {
    if (this.isBackendPaging) {
      this._pageNumber = 1;
      this.searchFn(this.postParam.modalID, this.postParam.postParam, this.postParam.searchType, this.postParam.isChkAuth, this.postParam.no_translation, this._pageNumber, this._pageSize, '',  '', this.searchMore.searchParam.condition).subscribe((config) => {
        if (config.data && config.data.datas && config.data.datas.length) {
          this._rowCount = config.data.rowCount;
          this.setGridData(config.data.datas);
        } else {
          this._rowCount = 0;
          this.setGridData([]);
        }
        this.isMoreConShow = false; // 查询完隐藏进阶查询
        // this.setCheckedStus(); // 设置选中的状态
      });
    } else {
      if (this._searchText) {
        const filterList = this.dataSet.filter((data, index) => {
          const strData = data.viewShowField ? JSON.stringify(data.viewShowField) : '';
         return strData.includes(this._searchText);
       });
       this._rowCount = filterList.length;
       this.setGridData(filterList.filter((item, index) => {
        return index >= (Number(this._pageSize) * (Number(this._pageNumber) - 1)) && index < (Number(this._pageSize) * Number(this._pageNumber));
       }));
      } else {
        this.setGridData(this.dataSet.filter((data, index) => {
          return index >= (Number(this._pageSize) * (Number(this._pageNumber) - 1)) && index < (Number(this._pageSize) * Number(this._pageNumber));
        }));
      }
      // this._gridApi.paginationSetPageSize(this._pageSize);
    //   this.setGridData(this.dataSet.filter((data, index) => {        
    //     return index >= (Number(this._pageSize) * (Number(this._pageNumber) - 1)) && index < (Number(this._pageSize) * Number(this._pageNumber));
    //  }));
    }
  }

  onClickOK(): void {
    // if (this.isBackendPaging) {// 后端分页
    //   this.selectedItem = this.selectedList;
    // } else { // 前端分页
    //   this.selectedItem = this._gridApi.getSelectedRows();
    // }
    
    this.selectedItem = this.selectedList;
    if (this.selectedItem.length < 1) {
      this.selectedItem = this._gridApi.getSelectedRows();
    }
    // if (this.isRequired && !this.selectedItem.length) {
    //   // this.dwMessage.warning(this.translateService.instant('comm-请勾选要操作的资料！'));
    // } else {
    //   this.modal.triggerOk();
    // }
      if (!this.selectedItem.length) {
        this.dwMessage.warning(this.translateService.instant('comm-请勾选要操作的资料！'));
      } else {
        this.modal.triggerOk();
      }
  }

  onClickCancel(): void {
    this.modal.triggerCancel();
  }
  fxCloseModal(): void {
    this.modal.triggerCancel();
  }
  // 最大化/还原
  fullModal(parm): void {
    //获取浏览器可见宽高
    let docWidth = window.outerWidth;
    let docHeight = window.outerHeight;
    let newModal = document.querySelector('.fx_modal_container');

    if(parm) {
      //存储第一次dwWidth值
      let oldW = this.modal['dwWidth'];
      // let oldH = this.modal;
      localStorage.setItem('width',oldW);
      let oldWidth = localStorage.getItem('width');

      //赋予新值
      this.layout.nativeElement.style.width = docWidth + 'px';
      this.layout.nativeElement.offsetParent.offsetParent.style.width = docWidth + 'px';
      this.layout.nativeElement.offsetParent.offsetParent.style.left = '0px';
      // this.isHeight = 'calc(100vh - 85px)';
      this.isHeight = '100vh';
      this.layout.nativeElement.offsetParent.offsetParent.style.height = this.isHeight;
      this.isFull = true;
      this.modal['dwStyle'].top = '0';
      //改写click传参值
      this.isParm = false;
    } else {
      this.layout.nativeElement.style.width = localStorage.getItem('width') + 'px';
      this.layout.nativeElement.offsetParent.offsetParent.style.width = localStorage.getItem('width') + 'px';  
      this.layout.nativeElement.offsetParent.offsetParent.style.left = '25%';
      this.isHeight = '325px';
      this.layout.nativeElement.offsetParent.offsetParent.style.height = this.isHeight;
      this.isFull = false;
      this.modal['dwStyle'].top = '20px';

      //改写click传参值
      this.isParm = true;
    }
  }

  checkEdge(event) {
    this.edge = event;
    console.log('edge:', event);
  }
}

