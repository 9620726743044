/**
 * Ng 框架的 cell components
 * 所有 cell components 以 cfc- 作为前缀
 * cfc <-- cachedFormCell
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CfcButtonEditorComponent} from './cfc-button-editor/cfc-button-editor.component';
import {CfcValidationEditorComponent} from './cfc-validation-editor/cfc-validation-editor.component';
import {FrameworkUIModule} from '@webdpt/framework';
import {MatAutocompleteModule} from '@angular/material';
import {PipesModule} from 'app/implementation/shared/components/pipe/pipes.module';
import {CfcTextRendererComponent} from './cfc-text-renderer.component';
import {CfcCheckboxRendererComponent} from './cfc-checkbox-renderer.component';
import {CfcHyperlinkRendererComponent} from './cfc-hyperlink-renderer.component';
import {CfcTextEditorComponent} from './cfc-text-editor/cfc-text-editor.component';
import {CfcSelectEditorComponent} from './cfc-select-editor/cfc-select-editor.component';
import {CfcSelectMultiEditorComponent} from './cfc-select-multi-editor/cfc-select-multi-editor.component';
import {CfcCheckboxEditorComponent} from './cfc-checkbox-editor/cfc-checkbox-editor.component';
import {CfcMperRendererComponent} from './cfc-mper-renderer/cfc-mper-renderer.component';
import { CfcSelectRendererComponent } from './cfc-select-renderer/cfc-select-renderer.component';
import { CfcSelectMultiRendererComponent } from './cfc-select-multi-renderer/cfc-select-multi-renderer.component';
import { CfcNumberFormatEditorComponent } from './cfc-number-format-editor/cfc-number-format-editor.component';
import { CfcColorRendererComponent } from 'app/implementation/shared/fx-grid/cell-component/cfc-color-renderer.component';
import { CfcOperationButtonRendererComponent } from 'app/implementation/shared/fx-grid/cell-component/cfc-operation-button-renderer.component';
import { CfcStatusRendererComponent } from 'app/implementation/shared/fx-grid/cell-component/cfc-status-renderer.component';
import {CfcNumberFormatRendererComponent} from './cfc-number-format-renderer.component';
import { CfcOperationButtonEditorComponent } from './cfc-operation-button-editor/cfc-operation-button-editor.component';
import { CfcDateEditorComponent } from './cfc-date-editor/cfc-date-editor.component';
import { CfcReadonlyTextEditorComponent } from './cfc-readonly-text-editor/cfc-readonly-text-editor.component';
import {CfcTextareaEditorComponent} from './cfc-textarea-editor/cfc-textarea-editor.component';
import { CellTextEditorComponent } from './cell-text-editor/cell-text-editor.component';
import { CellSelectEditorComponent } from './cell-select-editor/cell-select-editor.component';
import {CellButtonEditorComponent} from './cell-button-editor/cell-button-editor.component';
import { CellNumberFormatEditorComponent } from './cell-number-format-editor/cell-number-format-editor.component';
import { CellDateEditorComponent } from './cell-date-editor/cell-date-editor.component';
import { CellInputDateEditorComponent } from './cell-input-date-editor/cell-input-date-editor.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {AutocompleteModule} from '../../autocomplete/autocomplete.module';
import { CfcIndustryButtonEditorComponent } from './cfc-industrybutton-editor/cfc-industrybutton-editor.component';

export const FX_GRID_COMPONENTS: any = {
  cfcTextRenderer: CfcTextRendererComponent,
  cfcCheckboxRenderer: CfcCheckboxRendererComponent,
  cfcHyperlinkRenderer: CfcHyperlinkRendererComponent,
  cfcColorRenderer: CfcColorRendererComponent,
  cfcOperationButtonRenderer: CfcOperationButtonRendererComponent,
  cfcStatusRenderer: CfcStatusRendererComponent
};
export const FX_GRID_FRAMEWORK_COMPONENTS: any = {
  cfcSelectRenderer:CfcSelectRendererComponent,
  cfcSelectEditor: CfcSelectEditorComponent,
  cfcSelectMultiRenderer:CfcSelectMultiRendererComponent,
  cfcSelectMultiEditor: CfcSelectMultiEditorComponent,
  cfcButtonEditor: CfcButtonEditorComponent,
  cfcTextEditor: CfcTextEditorComponent,
  cfcReadonlyTextEditor: CfcReadonlyTextEditorComponent,
  cfcCheckboxEditor: CfcCheckboxEditorComponent,
  cfcMperRenderer:CfcMperRendererComponent,
  cfcNumberFormatRenderer:CfcNumberFormatRendererComponent,
  cfcNumberFormatEditor:CfcNumberFormatEditorComponent,
  cfcDateEditor:CfcDateEditorComponent,
  cfcOperationButtonEditor:CfcOperationButtonEditorComponent,
  cfcTextareaEditor: CfcTextareaEditorComponent,
  //单元格编辑渲染器
  cellTextEditor: CellTextEditorComponent,
  cellSelectEditor: CellSelectEditorComponent,
  cellButtonEditor: CellButtonEditorComponent,
  cellNumberFormatEditor: CellNumberFormatEditorComponent,
  cellDateEditor: CellDateEditorComponent,
  cellInputDateEditor: CellInputDateEditorComponent,
  cfcIndustryButtonEditor:CfcIndustryButtonEditorComponent
};

@NgModule({
  declarations: [CfcButtonEditorComponent,
    CfcValidationEditorComponent,
    CfcTextEditorComponent,
    CfcSelectEditorComponent,
    CfcSelectMultiEditorComponent,
    CfcCheckboxEditorComponent,
    CfcCheckboxEditorComponent,
    CfcMperRendererComponent,
    CfcSelectRendererComponent,
    CfcSelectMultiRendererComponent,
    CfcNumberFormatRendererComponent,
    CfcNumberFormatEditorComponent,
    CfcOperationButtonEditorComponent,
    CfcDateEditorComponent,
    CfcReadonlyTextEditorComponent,
    CfcTextareaEditorComponent,
    //单元格编辑渲染器
    CellTextEditorComponent,
    CellSelectEditorComponent,
    CellButtonEditorComponent,
    CellNumberFormatEditorComponent,
    CellDateEditorComponent,
    CellInputDateEditorComponent,
    CfcIndustryButtonEditorComponent
  ],

  imports: [
    CommonModule, FrameworkUIModule,
    MatFormFieldModule, MatAutocompleteModule, AutocompleteModule,
    PipesModule
  ],
  entryComponents: [
    CfcButtonEditorComponent, CfcTextEditorComponent, CfcSelectEditorComponent, CfcSelectMultiEditorComponent, CfcCheckboxEditorComponent,
    CfcMperRendererComponent, CfcSelectRendererComponent, CfcSelectMultiRendererComponent, CfcNumberFormatRendererComponent,
    CfcNumberFormatEditorComponent,
    CfcOperationButtonEditorComponent,
    CfcDateEditorComponent,
    CfcReadonlyTextEditorComponent,
    CfcTextareaEditorComponent,
    //单元格编辑渲染器
    CellTextEditorComponent,
    CellSelectEditorComponent,
    CellButtonEditorComponent,
    CellNumberFormatEditorComponent,
    CellDateEditorComponent,
    CellInputDateEditorComponent,
    CfcIndustryButtonEditorComponent
  ]
})
export class CellComponentModule {
}
