import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FxAutocompleteDirective} from './fx-autocomplete.directive';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

@NgModule({
  declarations: [FxAutocompleteDirective],
  imports: [
    CommonModule,
    MatAutocompleteModule
  ],
  exports: [
    FxAutocompleteDirective
  ],
  providers: [
  ]
})
export class AutocompleteModule { }
