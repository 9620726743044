import {Component, ElementRef, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {FxAgGridValidationEditorComponent} from '../../../ag-grid/fx-ag-grid-validation-editor';
import {ICellEditorAngularComp} from 'ag-grid-angular/src/interfaces';
import {CandyDate, DwDatePickerComponent} from 'ng-quicksilver';
import {ICellEditorParams} from 'ag-grid-community';
import {Subscription} from 'rxjs';
import {FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FxAgGridEventHandlerService} from '../../../ag-grid/fx-ag-grid-event-handler.service';
import {FxUserInfoService} from '../../../../auth/fx-auth/fx-user-info.service';
import {DatePipe} from '@angular/common';
import * as _ from 'lodash';
import {el} from '@angular/platform-browser/testing/src/browser_util';

@Component({
  selector: 'app-cell-date-editor',
  templateUrl: './cell-date-editor.component.html',
  styleUrls: ['./cell-date-editor.component.less']
})
export class CellDateEditorComponent  implements OnDestroy {
  formGroup: FormGroup;
  fParam: any;
  // disabledExpr: Function;
  // columnName: string;
  private rowId: number;
  private colId: string;
  private rowIndex: number;

  dateFormat = 'yyyy/MM/dd';
  dwShowToday = true;

  @ViewChild('cellInput') cellInput: DwDatePickerComponent;  // 获取子组件元素的实例引用 Added By David Lee 18Feb2019
  @ViewChild('cellInput') dateInput: ElementRef; 
  params: any;  // 存放cellEditorParams   Added By David Lee 18Feb2019
  // valueSetSubscription: Subscription;  // Added by David Lee 25Feb2019
  flag = true; //防止change事件重复触发

  message: string;
  is_ShowTime: false;
  dwDisabledDate; //禁用日期范围
  // constructor(private fb: FormBuilder, private fxUserService: FxUserInfoService, private datePipe: DatePipe) {
  // }

  constructor(private fb: FormBuilder, private fxUserService: FxUserInfoService, private datePipe: DatePipe) {
  }

  agInit(params: any): void {
    this.fParam = params;
    this.colId = params.column.colId;
    this.rowId = params.node.id;

    this.rowIndex = params.context.serviceObj.indexMap.get(this.rowId);
    const cellFromGroup = ((params.context.myFormArray as FormArray).at(this.rowIndex) as FormGroup);
    this.formGroup = cellFromGroup;

    // const rowFromGroup = params.context.serviceObj.rowCachedFormGroup;
    // this.formGroup = rowFromGroup;
    // this.disabledExpr = params.disabledExpr || ((params: any) => false);
    this.dwShowToday = (params.dwShowToday === undefined || params.dwShowToday === true) ? true : false;

    // this.formControl = _.cloneDeep(this.formGroup.get(this.key));
    // this.fParam = params;  // 把cellEditorParams参数赋给params Added By David Lee 18Feb2019

    this.dwDisabledDate = params.dwDisabledDate || '';
    this.is_ShowTime = params.is_ShowTime || false;
    // this.editType = params.api.gridOptionsWrapper.gridOptions.editType || '';
    this.dateFormat = params.dateFormat ? params.dateFormat : (this.dateFormat ? this.dateFormat : 'yyyy/MM/dd');
    
    let currentDate = null;
    const value = this.formGroup.get(this.colId).value;
    if (value !== 'Invalid Date' && value) {
      currentDate = new Date(this.datePipe.transform(value, this.dateFormat));
    } else {
      currentDate = null;
    }

    // 监听赋值 Add David lee 25 Feb 2019
    // this.valueSetSubscription = this.eventHandler.valueSet.subscribe(event => {
    //   if ( (event.colId === this.colId) && (event.gridId === params.context.id) ) {
    //     this.formControl.setValue(event.value);
    //     this.message = event.message;
    //     this.formControl.markAsDirty();
    //   }
    // });

    // super.init();
  }

  onKeyDown(event: any): void {
    if (event.keyCode === 27) {
      event.stopPropagation();
    }
    // if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38
    //   || event.keyCode === 39 || event.keyCode === 40) {
    //   event.stopPropagation();
    // }
  } 

  getValue(): any {
    var value = this.formGroup.get(this.colId).value;
    if (!value || value === 'Invalid Date') {
      return null;
    } else {
      // return  moment(this.formControl.value, this.dateFormat).format(this.dateFormat);
      const formValue = this.datePipe.transform(value, this.dateFormat);
      this.formGroup.get(this.colId).setValue(formValue);
      return formValue;
    }
  }

  afterGuiAttached(): void {
    // 这里是 row 在外部更新后，重新更新editor
    const valueChange = (value: string): void => {
      // 通过订阅消息回传onChangeField事件
      var formValue = this.formGroup.get(this.colId).value;
      if (!formValue || formValue === 'Invalid Date') {
        formValue = null;
        this.formGroup.get(this.colId).setValue(value);
      }
      this.formGroup.get(this.colId).markAsDirty({onlySelf: true});
    };
    this.cellInput.registerOnChange( valueChange) ;  // Added by David Lee 注册OnChange事件


    this.flag = true;
    this.cellInput.onOpenChange = (): void => {
      if (this.fParam.onOpenChange) {
        if ( this.flag === true) {
          this.fParam.onBeforeField({
            gridId: '',
            value: this.formGroup.get(this.colId).value,
            rowIndex: '',
            id: '',
            colId: '',
            controlName: ''
          });
          this.flag = false;
        }
      }
    };

    this.cellInput.onValueChange = (value: CandyDate): void => {
      const date = (value && value.getDate()) ? value.nativeDate : null;
      const rawValue = this.datePipe.transform(date, this.dateFormat);
      this.formGroup.get(this.colId).setValue(rawValue);
      if (this.is_ShowTime === false) {
        this.cellInput.closeOverlay();
      }
      if ( this.fParam.onChange !== undefined) {
       this.fParam.onChange({
        gridId: this.fParam.context.gridId || '',
        value: rawValue,
        rowIndex: this.rowIndex,
        id: this.rowId,
        colId: this.colId,
        controlName: ''
       });
      }
    };
  }

  // focusIn(): void {
  //   this.cellInput.onTouchedFn();
  //   if (this.fParam.onFocus) {
  //     this.fParam.onFocus({
  //       gridId: '',
  //       value: this.formGroup.get(this.colId).value,
  //       rowIndex: '',
  //       id: '',
  //       colId: '',
  //       controlName: ''
  //     });
  //   }
  // }

  // focusOut(): void {
  //   // this.cellInput.ngOnChanges();
  //   if (this.fParam.onBlur) {
  //     this.fParam.onBlur({
  //       gridId: '',
  //       value: this.formGroup.get(this.colId).value,
  //       rowIndex: '',
  //       id: this.rowId,
  //       colId: '',
  //       controlName: ''
  //     });
  //   }
  // }

  isCancelAfterEnd(): boolean {
    return false;
  }

  isCancelBeforeStart(): boolean {
    return false;
  }

  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    // this.validateForm.removeControl('');
    // 当销毁时取消所有订阅
    // if (this.valueSetSubscription) {
    //   this.valueSetSubscription.unsubscribe();
    // }
  }
}
