import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {CfcAgGridValidationRendererComponent} from '../cfc-validation-renderer';

@Component({
  selector: 'app-cfc-select-renderer',
  templateUrl: './cfc-select-renderer.component.html',
  styleUrls: ['./cfc-select-renderer.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CfcSelectRendererComponent implements ICellRendererAngularComp {
  status: string = 'valid';
  values: any;
  public formControl: FormControl;
  // key;
  public value;
  public label: string = '';
  // columnName: string;
  params;
  public className: string;
  // private rowId: number;

  errorVisible: any;

  constructor() { }

  agInit(params: any): any {
    this.values = params.values ? params.values : [];
    this.className = params.className ? params.className : '';
    this.params = params;

    this.value = params.getValue();
    if (Array.isArray(this.values)) {
      this.label = this.findValue(this.values);
    } else {
      const observable: Observable<any> = this.values;
      observable.subscribe(data => {
        this.label = this.findValue(data);
      });
    }

    if (params.vstatus) {
      const _valueStatus = ((typeof params.vstatus === 'function'
        ? params.vstatus(params)
        : params.vstatus) || {}).status as string || '';
      this.status = _valueStatus.toLowerCase();
    }
    if (params.context.formArrayId && params.context.serviceObj) {
      const id = [params.context.formArrayId, params.context.serviceObj.indexMap.get(params.data.id), params.column.colId].join('@');
      const vformControl = params.context.serviceObj.invalidFormControls.get(id);
      if (vformControl && vformControl.status.toLowerCase() === 'invalid') {
        this.status = 'invalid';
      }
    }
  }

  findValue(arr: Array<any>): string {
    const matchedItem = arr.find(item => item.value === this.value);
    return matchedItem ? matchedItem.label : '';
  }

  refresh(params: any): boolean {
    // this.value = params.value;
    // if (Array.isArray(this.values)) {
    //   this.label = this.findValue(this.values);
    // } else {
    //   const observable: Observable<any> = this.values;
    //   observable.subscribe(data => {
    //     this.label = this.findValue(data);
    //   });
    // }
    return true;
  }

  labelClick(event: any): void {
    if (this.params && this.params.click) {
      this.params.click(this.params);
    }
  }

}
