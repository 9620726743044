import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {ConnectableObservable, Observable, of} from 'rxjs';
import {FxDapHttpClient} from '../../../fx-http-client';
import {FxModalService} from '../../../components/fx-modal/fx-modal.service';
import {ICellEditorParams} from 'ag-grid-community';
import {concatMap, publishLast} from 'rxjs/operators';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {CellValidationEditorComponent} from '../cell-validation-editor/cell-validation-editor.component';
import {Hotkey, HotkeysService} from 'angular2-hotkeys-d';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';

@Component({
  selector: 'app-cell-button-editor',
  templateUrl: './cell-button-editor.component.html',
  styleUrls: ['./cell-button-editor.component.less']
})
export class CellButtonEditorComponent extends CellValidationEditorComponent implements ICellEditorAngularComp, OnDestroy {
  // 行编辑下，当前行的 formGroup
  rowFormGroup: FormGroup;
  // 本栏位的 formControlName
  formControlName: string;
  // 栏位名字
  columnName: string;
  // 当前行 (rowData) 的 id 值
  // private rowId: number;

  // 控件 disable 表达式
  disabledExpr: Function;

  // 模态框工厂方法
  modalService;
  // 模态框可以多选
  multiSelection = false;

  @ViewChild('cellInput') cellInput: ElementRef;

  // cell editorParams
  editorParams: any;  // 存放cellEditorParams   Added By David Lee 18Feb2019

  message: string;

  // dwDisabled: boolean = false;  // add by canon

  matOptionShow: boolean = false;  // add by quwj
  options: Observable<any>;

  isBackendPaging: Boolean = false; // 是否后端分页，默认非后端分页

  isReGetDropDownData: boolean = true; // 是否重新获取开窗下拉的值，默认true
  activeId: any; // 记录鼠标移动到的数据id

  hotkeysService: HotkeysService;

  pressEnterDownCount: number = 0;

  constructor(public http: FxDapHttpClient, public fxModalService: FxModalService) {
    super();
  }

  onKeyDown(event: KeyboardEvent): void {
    // return;
    // if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38
    //   || event.keyCode === 39 || event.keyCode === 40) {
    //   event.stopPropagation();
    // }

    // 开窗快捷键： mod + shift + F
    if (event.key.toLowerCase() === 'f' && event.ctrlKey && event.shiftKey) {
      this.openModal();
      return;
    }

    // 需用上下方向键切换模糊匹配项
    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.stopPropagation();
    }

    // 按用户要求，在单元格的编辑框内按下 Enter 时，需要切换到下一行的单元格
    // 但：在 autocomplete options 展开时，按下 Enter 效果是选中 option。
    // 这种情况下，光标留在当前单元格会更加自然
    if (event.key === 'Enter') {
      if (this.pressEnterDownCount > 0) {
        event.stopPropagation();
        this.pressEnterDownCount--;
      }
    }

    // if (event.keyCode === ENTER || event.keyCode === TAB) {
    //   if (this.activeId && this.activeId !== this.rowFormGroup.get(this.formControlName).value && this.matOptionShow && !(this.editorParams['param'](this.rowFormGroup))['disableCheck'] && !this.isBackendPaging) {
    //     this.rowFormGroup.get(this.formControlName).patchValue(this.activeId);
    //   }
    //   // this.cellInput.nativeElement.onchange();
    // }
    // 绑定上下键事件
    // if (event.keyCode === DOWN_ARROW || event.keyCode === UP_ARROW) {
    //   if (!this.activeId) {
    //     this.options.subscribe(data => {
    //       if (data.length) {
    //         this.onMouseEnter(data[0]);
    //       }
    //     });
    //   } else {
    //     this.options.subscribe(data => {
    //       if (data.length) {
    //         const idx = data.findIndex(item => {
    //           return item.id === this.activeId;
    //         });
    //         if (idx !== -1) {
    //           const newIdx = event.keyCode === DOWN_ARROW ? (idx + 1) : (idx - 1);
    //           if (newIdx <= (data.length - 1) && newIdx >= 0) {
    //             this.onMouseEnter(data[newIdx]);
    //             this.scrollIntoView(newIdx);
    //           } else if (newIdx <= (data.length - 1) && newIdx < 0) {
    //             this.onMouseEnter(data[data.length - 1]);
    //             this.scrollIntoView(data.length - 1);
    //           } else {
    //             this.onMouseEnter(data[0]);
    //             this.scrollIntoView(0);
    //           }
    //         } else {
    //           this.onMouseEnter(data[0]);
    //           this.scrollIntoView(0);
    //         }
    //       }
    //     });
    //   }
    // }
  }

  // 滚动条位置
  scrollIntoView = (idx): void => {
    const height = 32; // 下拉每条数据的高度
    if (document.querySelector('.mat-autocomplete-panel')) {
      document.querySelector('.mat-autocomplete-panel').scrollTop = (height * idx);
    }
  }

  // gets called once after the editor is created
  agInit(params: ICellEditorParams | any): void {
    this.columnName = params.column.colDef.headerName;
    this.colId = params.column.colId;
    this.formControlName = params.column.colId;
    this.rowId = params.node.id;
    // const rowFromGroup = params.context.componentParent.rowCachedFormGroup;
    // todo 这里改为 params.context.rowCachedFormGroup;
    const index = params.context.serviceObj.indexMap.get(this.rowId);
    this.rowFormGroup = ((params.context.myFormArray as FormArray).at(index) as FormGroup);

    this.rowFormGroup.get(this.formControlName).markAsPristine({onlySelf: true});

    // 如果是直接按键编辑栏位，从 params.cellStartedEdit 获取初值
    this.initFirstValue(params);
    this.editorParams = params;

    // 如果 this.editorParams.params 不是 Function ，包装成 Function
    if (this.editorParams && this.editorParams.param && (typeof this.editorParams.param !== 'function')) {
      const result = this.editorParams.param;
      this.editorParams.param = (param: any): any => result;
    }

    const currentValue = this.valueTransfer(params.value);
    this.modalService = params['modalService'];

    // this.dwDisabled = params.dwDisabled;

    // if (this.cellInput) {
    // 通过订阅消息回传onFocus事件
    this.cellInput.nativeElement.onfocus = (e: any): void => {
      this.getDropDownList();
      this.matOptionShow = true;
      this.onFocus();
    };

    // 通过订阅消息回传onChange事件
    this.cellInput.nativeElement.onchange = (event): void => {
      this.getDropDownList();
    };

    // 通过订阅消息回传onChange事件
    this.cellInput.nativeElement.onblur = (event): void => {
      this.matOptionShow = false;
      this.onBlur();
    };

    super.init();
  }

  onInputBlur(e: any): void {
    // this.matOptionShow = false;
    // this.onBlur();
  }

  initFirstValue(params: ICellEditorParams | any) {
    let startValue;
    let valueChanged = false;
    // cellStartedEdit is only false if we are doing fullRow editing
    if (params.cellStartedEdit) {
      const keyPressBackspaceOrDelete = params.keyPress === 8
        || params.keyPress === 46;
      if (keyPressBackspaceOrDelete) {
        startValue = '';
        valueChanged = true;
      } else if (params.charPress) {
        startValue = params.charPress;
        valueChanged = true;
      } else {
        startValue = params.value;
        valueChanged = false;
      }
    }
    if (startValue !== null && startValue !==  undefined && startValue !== '') {
      this.rowFormGroup.get(this.formControlName).setValue(startValue);
      // 只有编辑内容后才标记为 dirty
      if (valueChanged) {
        this.rowFormGroup.get(this.formControlName).markAsDirty();
      }
    }
  }

  openModal(): void {
    try {
      const openFn = (this.editorParams as any).openModelFn({
        value: this.rowFormGroup.get(this.formControlName).value,
        id: this.rowId
      });
      if ('object' === typeof openFn && 'function' === typeof openFn.subscribe) {
        (openFn as Observable<any>).subscribe(() => {
          this.cellInput.nativeElement.focus();
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  }
  onFocus(): void {
    try {
      if ((this.editorParams as any).onFocusFn) {
        (this.editorParams as any).onFocusFn({
          value: this.rowFormGroup.get(this.formControlName).value
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  onBlur(): void {
    try {
      // 只有修改过，才调用 onBlur 回调方法 (onBlur 当 onchange 使用)
      if (!this.rowFormGroup.get(this.formControlName).dirty) {
        return;
      }
      // 临时做法
      // 现在 isCancelAfterEnd 和 input.onBlur 都会调用 this.onBlur 方法
      // 单元格编辑场景，
      // A. 如果是在 grid 内离开单元格，上面两个事件都会触发，导致 this.onBlur() 执行两处
      // B. 如果是点击 grid 外部导致失去焦点，只有 input.onBlur 会触发
      // C. 如果是按 ESC 键导致退出编辑，只有 isCancelAfterEnd 会触发
      // 因此，在这里重置 formControl 状态，避免方法重复执行。
      this.rowFormGroup.get(this.formControlName).markAsPristine({onlySelf: true});

      (this.editorParams as any).onBlurFn({
        value: this.rowFormGroup.get(this.formControlName).value,
        id: this.rowId
      });
    } catch (ex) {
      console.error(ex);
    }
  }

  // Gets called once after GUI is attached to DOM.
  // Useful if you want to focus or highlight a component
  // (this is not possible when the element is not attached)
  afterGuiAttached(): void {
    if (this.cellInput && !this.isFullRowEdit()) {
      this.cellInput.nativeElement.focus();
    }
    this.hotkeysService = new HotkeysService({
        target: this.cellInput.nativeElement
    });
    this.hotkeysService.add(new Hotkey('mod+alt+f', (event: KeyboardEvent, combo: string): boolean => {
        this.openModal();
        return true;
    }, ['INPUT']));
  }

  private valueTransfer(value: any): any {
    let currentValue = value;
    if (this.multiSelection) {
      if (typeof value === 'string') {
        currentValue = value.split(',').map(
          v => v.trim()
        );
      }
    } else {
      if (Array.isArray(value)) {
        currentValue = value.join();
      }
    }
    return currentValue;
  }

  getSelectedValue(): any {
    return this.rowFormGroup.get(this.formControlName).value;
  }

  setSelectedValue(value: any): any {
    this.rowFormGroup.get(this.formControlName).patchValue(value);
    this.rowFormGroup.get(this.formControlName).markAsDirty();
  }

  // If doing full row edit, then gets called when tabbing into the cell.
  focusIn(): void {
    this.cellInput.nativeElement.focus();
  }

  // If doing full row edit, then gets called when tabbing out of the cell.
  focusOut(): void {
    this.cellInput.nativeElement.blur();
  }

  // Should return the final value to the grid, the result of the editing
  getValue(): any {
    return this.getSelectedValue();
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd(): boolean {
    this.onBlur();
    return this.validationMessages.length > 0;
  }

  // Gets called once after initialised.
  // If you return true, the editor will appear in a popup
  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void {
    this.hotkeysService.reset();
    super.destroy();
  }

  // 获取下拉获取数据
  getData(): Observable<any> {
    return Observable.create(observer => {
      const dropDownParam = (this.editorParams['param'](this.rowFormGroup))['dropDownParam'];
      this.fxModalService.searchFx(dropDownParam).subscribe(
        (data) => {
          const result = data.map(item => {
            item.id = item[(this.editorParams['param'](this.rowFormGroup))['id']];
            item.name = item[(this.editorParams['param'](this.rowFormGroup))['name']];
            return item;
          });
          // 后端分页则不用下拉否则使用下拉
          if (result[0].isBackendPaging) {
            this.isBackendPaging = true;
            observer.next([]);
          } else {
            observer.next(result);
          }
          observer.complete();
        },
        (error) => {
          observer.next([]);
          observer.complete();
        }
      );
    });
  }

  // 设置开窗下拉列表数据
  getDropDownList(): void {
    // 不设置pram则不会有下拉
    if (!this.editorParams['param']) {
      return;
    }
    if (!this.isReGetDropDownData && !(this.editorParams['param'](this.rowFormGroup))['reGetData'] || (this.editorParams['param'](this.rowFormGroup))['disableCheck'] || this.isBackendPaging) {
      return;
    }
    this.isReGetDropDownData = false;
    if (!(this.editorParams['param'](this.rowFormGroup))['customMethod']) {
      this.options = this.getData().pipe(publishLast());
      (this.options as ConnectableObservable<any>).connect();
    } else {
      this.options = this.http.post((this.editorParams['param'](this.rowFormGroup))['customMethod'], (this.editorParams['param'](this.rowFormGroup))['customParam'])
        .pipe(concatMap(res => {
          let data;  // 存放服务返回的数据

          data = res.data || [];
          const result = data.map(item => {
            item.id = item[(this.editorParams['param'](this.rowFormGroup))['id']];
            item.name = item[(this.editorParams['param'](this.rowFormGroup))['name']];
            return item;
          });
          return of(result);
        })).pipe(
          publishLast()
        );
      (this.options as ConnectableObservable<any>).connect();
    }
  }

  // 鼠标移入设置activeId
  onMouseEnter(item: any, event?: any): void {
    this.activeId = item.id;
  }

  // 点击下拉框的数据给input赋值
  setData(option: any): void {
    if (this.rowFormGroup.get(this.formControlName).value !== option.id) {
      this.setSelectedValue(option.id);
      this.cellInput.nativeElement.onchange();
    }
  }

  selectOption(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    this.setSelectedValue(value);
    this.pressEnterDownCount++;
  }
}
