import { Observable, of } from 'rxjs';
import { AbstractControl, ValidatorFn, FormGroup, FormArray, FormControl } from '@angular/forms';
import { RowNode } from 'ag-grid-community';
import { DwHttpClient } from '../../../../../projects/webdpt/framework/http';


// 根据作业编号获取作业名称
export function getMenuName(id: any): string {
  const fxMenuDataJson = sessionStorage.getItem('fxMenuDataJson');
  var name = '';
  if (fxMenuDataJson) {
    const fxMenuDataList = JSON.parse(fxMenuDataJson);
    const fxMenuDataIdx = fxMenuDataList.findIndex(obj => {
      return obj.id == id;
    });
    if (fxMenuDataIdx !== -1) {
      name = fxMenuDataList[fxMenuDataIdx].name;
    }
  }
  return name;
}

/**
 * 日期计算器
 * type  计算类型  值year\month\day 代表计算年、月、日
 * num   计算数量  往后计算使用正数，往前计算使用负数
 * startDate 计算开始日期  不传值默认以当前日期计算
 * return <string> 计算后日期
 */
export function calDate(type, num, startDate) {
  var date = startDate ? new Date(startDate) : new Date();
  if (type == 'day') {
    date.setDate(date.getDate() + num);
  } else if (type == 'month') {
    date.setMonth(date.getMonth() + num);
  } else if (type == 'year') {
    date.setFullYear(date.getFullYear() + num);
  }
  var day = date.getDate().toString();
  var month = (date.getMonth() + 1).toString();
  var year = date.getFullYear();
  if (month < '10') {
    month = "0" + month;
  }
  if (day < '10') {
    day = "0" + day;
  }
  var val = year + "/" + month + "/" + day;
  return val;
};

// 判断商品型号是否必输如果必输且只有一个特征则带出来，否则作业自己处理
export function getGoodsChr(http: DwHttpClient, param): Observable<any> {
  return Observable.create(observer => {
     http.post<any>('C/ICCommonService/getCharInfoB2C', { param: param }).subscribe(response => {
      if (response['success'] && response['data2'] && response['data2'].length) {
        observer.next(response['data2']);
      } else {
        observer.next([]);
      }
      observer.complete();
    }, error => {
      observer.next([]);
      observer.complete();
    });
  });
}


/**
 * 判断商品型号是否必输如果必输且只有一个特征则带出来，否则作业自己处理-批量
 * http  http请求
 * param 服务请求参数
 * dataList 需要获取特征的商品集合
 * goodField 商品名称
 * goodTypeField 商品类型名称
 * chrNoField 商品特征编号名称
 * chrNameField 商品特征名称
 */
export function getGoodsChrList(http: DwHttpClient, param, dataList, chrNoField, chrNameField, goodField, goodTypeField?: string): Observable<any> {
  return Observable.create(observer => {
     http.post<any>('C/ICCommonService/getCharInfoB2CList', { param: param }).subscribe(response => {
       if (response.success && response.data && response.data.length) {
        response.data.forEach(resItem => {
          const idx = dataList.findIndex(addItem => {
            if (goodTypeField) {
              return addItem[goodField] == resItem.model_no && addItem[goodTypeField] == resItem.goods_type;
            } else {
              return addItem[goodField] == resItem.model_no;
            }
          });
          if (idx !== -1 && resItem['data2'] && resItem['data2'].length === 1) {            
            dataList[idx][chrNoField] = resItem['data2'][0].character_no; // 商品特征编号
            dataList[idx][chrNameField] = resItem['data2'][0].character_n1;// 商品特征名称
          }
        }); 
       }
      observer.next(dataList);
      observer.complete();
    }, error => {
      observer.next(dataList);
      observer.complete();
    });
  });
}


/**
 * Deep clones the given AbstractControl, preserving values, validators, async validators, and disabled status.
 * @param control AbstractControl
 * @returns AbstractControl
 */
export function cloneAbstractControl<T extends AbstractControl>(control: T): T {
  let newControl: T;

  if (control instanceof FormGroup) {
    // const formGroup = new FormGroup({}, control.validator, control.asyncValidator);
    const formGroup = new FormGroup({});
    const controls = control.controls;

    Object.keys(controls).forEach(key => {
      formGroup.addControl(key, cloneAbstractControl(controls[key]));
    });
    formGroup.setValidators(control.validator);
    formGroup.setAsyncValidators(control.asyncValidator);

    newControl = formGroup as any;
  } else if (control instanceof FormArray) {
    const formArray = new FormArray([], control.validator, control.asyncValidator);

    control.controls.forEach(formControl => formArray.push(cloneAbstractControl(formControl)));

    newControl = formArray as any;
  } else if (control instanceof FormControl) {
    newControl = new FormControl(control.value, control.validator, control.asyncValidator) as any;
  } else {
    throw new Error('Error: unexpected control value');
  }

  if (control.disabled) newControl.disable({emitEvent: false});

  return newControl;
}


/**
 *  判断时间大小
 * type === '1' || !type: 开始时间不可大于结束时间
 * type === '2': 开始时间不可大于等于结束时间
 * type === '3': 结束时间不可大于开始时间
 * type === '4': 结束时间不可大于等于开始时间
 */
//
export function compareTimeSize(startDate, endDate, type?: any) {
  if (!startDate || !endDate) {
    return true;
  } else {
    const startTime = (new Date(startDate)).getTime();
    const endTime = (new Date(endDate)).getTime();
    if (!type || type === '1') {
      if (startTime > endTime) {
        return false;
      } else {
        return true;
      }
    } else if (type === '2') {
      if (startTime >= endTime) {
        return false;
      } else {
        return true;
      }
    } else if (type === '3') {
      if (endTime > startTime) {
        return false;
      } else {
        return true;
      }
    } else if (type === '4') {
      if (endTime >= startTime) {
        return false;
      } else {
        return true;
      }
    }
  }
};
/**
 * 枚举类型根据value 找到数据源dataSource 对应的说明
 * @param dataSource 需过滤数据源
 * @param value 过滤值
 */
export function getDisplayLabel(dataSource: any, value: any): Observable<any> {
  return Observable.create(observable => {
    if (Array.isArray(dataSource)) {
      var datafilter = dataSource.find(item => item.value === value);
      if (datafilter) {
        var labelVal = datafilter.label ? datafilter.label : '';
      }
      observable.next(labelVal ? labelVal : '')
      observable.complete();
    } else {
      const obser: Observable<any> = dataSource;
      obser.subscribe(data => {
        var datafilter = data.find(item => item.value === value);
        if (datafilter) {
          var labelVal = datafilter.label ? datafilter.label : '';
        }
        observable.next(labelVal ? labelVal : '');
        observable.complete();
      });
    }
  })
}


/**
 * 将默认条件带入到更多查询页面显示
 * @param searchMore 公共的查询组件
 * @param conditionList 默认条件数组
 */
 export function setSearchMoreCondition(searchMore: any, conditionList: Array<any>): any {
  let addList = [];
  conditionList.forEach(conItem => {
    let type = null;
      if (conItem.type === '1' || conItem.type === '3') {
        type = 'all';
      } else if (conItem.type === '2' || conItem.type === '6') {
        type = 'select';
      } else if (conItem.type === '4' || conItem.type === '5') {
        type = 'date';
      } else if (conItem.type === '7' || conItem.type === '3') {
        type = 'month';
      } else {
        type = 'all';
      }
      // 如果有空查询条件则赋值给当前为空的查询条件，否则新增查询条件
    const idx = searchMore.searchVal.findIndex(searchItem => {
      return searchItem.field_no == conItem.name || (!searchItem.field_no && !searchItem.value)
    });
    if (idx !== -1) {
      searchMore.searchVal[idx] = Object.assign(searchMore.searchVal[idx], {
        table_no: conItem.table_no,
        field_no: conItem.name,
        condition: conItem.operator,
        value: conItem.value,
        cond_type: type,
      });
    } else {
      var newObj = Object.assign(searchMore.searchValObj, {
        table_no: conItem.table_no,
        field_no: conItem.name,
        condition: conItem.operator,
        value: conItem.value,
        label_type: conItem.label_type || '3',
        cond_type: type,
      })
      addList.push(newObj);
    } 
  });
  if (addList.length) {
    searchMore.searchVal.push(...addList)
  }
  return searchMore.searchVal;
}


/**
 * 点击单身快递单号根据按钮权限判断是否有权限，有则查询快递轨迹，否则提示处理
 * http  http请求
 * param 服务请求参数
 * btnId权限按钮名称
 */
export function getBtnAuth(http: DwHttpClient, param, btnId): Observable<any> {
  return Observable.create(observer => {
    http.post<any>('A/IACommonService/showButtonDisplay', { param: param }).subscribe(response => {
      let hasAuth = false;
      if (response.success && response.data && response.data.length) {
        const idx = response.data.findIndex(resItem => {
          return resItem.id == btnId;
        }); 
        if (idx !== -1) {
          hasAuth = true;
        }
      }
      observer.next(hasAuth);
      observer.complete();
    }, error => {
      observer.next(false);
      observer.complete();
    });
  });
}
