import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FxDapHttpClient } from '../../fx-http-client';
import { ActivatedRoute } from '@angular/router';
import { DwRoutingMessageService, DwNewRouteKeyService } from '@webdpt/framework';

@Component({
  selector: 'fx-export',
  templateUrl: './fx-export.component.html',
  styleUrls: ['./fx-export.component.less']
})
export class FxExportComponent implements OnInit {
  @Input() exportData: any = {}; // 导出资料
  @Input() btnData: any;    // 按钮资料
  @Input() isMoreBtn: any;    // 判断是否是更多按钮
  @Output() onButton = new EventEmitter<boolean>();
  public programId: string = '';   // 作业编号
  public exportList: Array<any>;  // 导出列表



  constructor(
    public http: FxDapHttpClient,
    private translateService: TranslateService,
    protected route: ActivatedRoute,
    public dwMessage: DwRoutingMessageService
  ) {
  }

  ngOnInit() {
    // 判断是否仅导出当前页--默认导出可选
    // this.exportData.exportType = this.exportData.exportType ? this.exportData.exportType : 2;
    // 判断是否添加边框--默认无
    this.exportData.hasBorder = this.exportData.hasBorder ? this.exportData.hasBorder : 'false';

    // 通过路由获取作业编号
    this.programId = this.route.snapshot['_routerState'].url.split(';')[0].replace('/dwTabRouting/', '');
    // 导出列表
    if (this.exportData && this.exportData.exportList && this.exportData.exportList.length) { // 判断作业是否是自定义导出
      this.exportList = this.exportData.exportList;
    } else {
      this.exportList = [{
        id: "current",
        name: this.translateService.instant('export-current')
      }, {
        id: "all",
        name: this.translateService.instant('export-all')
      }];
    }

  }

  // 导出服务资料Excel URL
  public tplExport(isAll, exportItem) {
    if (this.exportData.exportList && this.exportData.exportList.length) {// 自定义导出
      var newExpparam = JSON.parse(JSON.stringify(exportItem.serviceList));
      if (!newExpparam || !newExpparam.length) {
        this.dwMessage.warning(this.translateService.instant('comm-无资料可导出！'));
        return;
      }
    } else {// 标准导出
      var newExpparam = JSON.parse(JSON.stringify(this.exportData.serviceList));
      if (!newExpparam || !newExpparam.length) {
        return;
      }
    }
    if (exportItem.id == 'selected') { //导出选中数据未选中数据
      var idx = newExpparam.findIndex(ele => {
        return ele.hasOwnProperty('paramJson') && ele.paramJson.hasOwnProperty('queryInfo') && ele.paramJson.queryInfo.hasOwnProperty('isExport');
      });
      if (idx !== -1) {
        const keyName = exportItem.serviceList[idx].paramJson.queryInfo.exportKeyName ? exportItem.serviceList[idx].paramJson.queryInfo.exportKeyName : 'docnoList'; // 获取导出选中的key值名称
        if (exportItem.serviceList[idx].paramJson.queryInfo.isExport == 'Y' && (!exportItem.serviceList[idx].paramJson.queryInfo[keyName] || !exportItem.serviceList[idx].paramJson.queryInfo[keyName].length)) {
          this.dwMessage.warning(this.translateService.instant('comm-selected'));
          return;
        }
      }
    }
    var serviceList = [];
    if (isAll === 'Y') {
      const idx = newExpparam.findIndex(ele => {
        return ele.hasOwnProperty('paramJson') && ele.paramJson.hasOwnProperty('queryInfo');
      });
      if (idx !== -1) {
        if (!newExpparam[idx].isCustomParam) {// 自定义参数全部导出额外处理
          newExpparam.forEach(obj => {
            if (obj.paramJson.param) {
              obj.paramJson.param.pageSize = 9999999;
              obj.paramJson.param.pageNumber = 1;
            } else {
              if (obj.paramJson.queryInfo !== undefined) {
                obj.paramJson.queryInfo.pageSize = 9999999;
                obj.paramJson.queryInfo.pageNumber = 1;
              }
            }

          })
          // newExpparam[idx].paramJson.queryInfo.pageSize = 9999999; // 导出全部--默认最大数据量为9999999
          // newExpparam[idx].paramJson.queryInfo.pageNumber = 1;
        }
      }
    }
    serviceList = newExpparam;
    serviceList.forEach(ele => {
      if (!ele.isCustomParam) { // 自定义参数
        ele.paramJson = JSON.stringify(ele.paramJson);
      }
    });

    // 数据导出
    const exportParam = {
      'fileName': this.exportData.fileName,                   // 导出名称
      'hasBorder': this.exportData.hasBorder,  // 导出是否添加边框      
      'program_no': this.exportData.program_no ? this.exportData.program_no : this.programId,  // 作业编号
      'serviceList': serviceList
    }
    this.http.post('A/IExportExcelService/export', { param: exportParam }).subscribe(
      response => {
        if (response.success && response.data) {
          window.open(response.data);
        } else {
          this.dwMessage.errorNew(response.message, response.isRemind, response.programNo, response.programName);
        }
      });
  };
}
