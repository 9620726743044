import { Component, OnInit, AfterViewInit, Injector, Input, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core';
import { DwRoutingMessageService, DwNewRouteKeyService } from '@webdpt/framework';
import { FxModalService } from 'app/implementation/shared/components/fx-modal/fx-modal.service';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import { FX_AG_GRID_FW_COMPONENTS } from '../../../shared/fx-ag-grid-editors-module';
import { ActivatedRoute } from '@angular/router';
import { FxAgGridEventHandlerService } from '../../../shared/ag-grid/fx-ag-grid-event-handler.service';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';

@Component({
  selector: 'app-lang',
  templateUrl: './fx-lang.component.html',
  styleUrls: ['./fx-lang.component.less'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    FxAgGridEventHandlerService, // 注：建立新的实例，才不会造成作业间事件的错误

  ]
})
export class FxLangModalComponent implements OnInit, AfterViewInit {
  @Input() programId: string; // 作业编号
  @Input() data: any;
  public gState = 'browse'; // 控制是否可以编辑
  public langList: Array<any> = [];// 语系
  public fieldObj: any = {}; // 记录修改的栏位
  public masterGridOptions: GridOptions; // 列表ag-grid
  public masterColumnDefs = []; // 列表列定义 
  public masterList = [];
  public masterListInitData = []; // 记录初始化未修改的数据masterList
  public masterGridApi: GridApi; // 列表GridApi
  public masterGridColumnApi: ColumnApi; // 列表GridColumnApi
  public frameworkComponents = {   // Ag-grid 组件
    ...FX_AG_GRID_FW_COMPONENTS
  };

  // 构造函数
  constructor(private injector: Injector,
    protected http: FxDapHttpClient,
    private fb: FormBuilder,
    protected router: DwNewRouteKeyService,
    protected route: ActivatedRoute,
    public dwMessage: DwRoutingMessageService,
    private translateService: TranslateService,
    public fxModalService: FxModalService,
    protected agGridEventHandler: FxAgGridEventHandlerService, // 注入事件服务
    private fxUserService: FxUserInfoService,
  ) {

  }

  // OnInit
  ngOnInit(): void {
    this.pageInit();  // 页面初始化
  }

  // AfterViewInit
  ngAfterViewInit(): void {
    console.log('DavidLee for Test No1'); // 测试：CaseU001：gridReady可能会在Ag-grid中的Angular component is ready 前触发
  }

  // 过滤条件多语系
  localTextFunc = (key: string): any => {
    // grid多语系
    return this.translateService.instant(key);
  }

  // 初始化页面
  pageInit(): void {

    // 设定 masterGridOptions  的context 方便做父子Component及主从Ag-Grid通信
    this.masterGridOptions = <GridOptions>{
      context: {
        componentParent: this,
        id: 'masterGrid'          // 给ag-Grid指定ID
      },
      rowHeight: 28, //行高
      columnTypes: {
        'masterTextColumn': {
          editable: false,
          cellEditor: 'fxAgTextCellEditor',
          cellEditorParams: {
          }
        }
      }
    };
    this.masterGridInit();
    this.searchData();
  }

  // 列表初始化
  public masterGridInit(): void {
    // this.masterColumnDefs = [
    //   {// 语系
    //     headerName: this.translateService.instant('comm-lang'),
    //     width: 150,
    //     field: 'aaa002_n1',
    //     editable: false
    //   }
    // ];
  }

  // 单头gridReady事件，处理表格显示控制
  onMasterGridReady(params: any): void {
    this.masterGridApi = params.api;
    this.masterGridColumnApi = params.columnApi;
  }

  /**
   * 查詢資料
   * @param {boolean} [reset=false] 是否重新指定當前頁碼為第一頁
   */
  public searchData(): void {
    this.fxUserService.getSysEnumById$('6').subscribe(langRes => {
      this.langList = langRes.filter(s => {
        return s.aaa004;
      });
      this.masterList = JSON.parse(JSON.stringify(this.langList));// 设置了几个语系就显示几笔资料
      this.masterList.forEach((s, index) => {
        s.id = index + 1;
      })
      this.http.post('A/Ii18nService/getI18NInfo', { param: this.data }).subscribe(
        response => {
          if (response.success) {
            this.masterColumnDefs = [
              {// 语系
                headerName: this.translateService.instant('comm-lang'),
                width: 150,
                field: 'aaa002_n1',
                editable: false
              }
            ];
            this.fieldObj = response.data;
            for (var key in response.data) {
              // 动态栏位，response.data返回需要编辑的栏位
              this.masterColumnDefs.push({
                headerName: this.translateService.instant(key),
                width: 220,
                field: key,
                cellEditor: 'fxAgTextCellEditor',
                editable: (param) => {
                  console.log(param);
                  return param.data[key + '_edit']
                }
              })
              // 组合栏位
              this.masterList.forEach(s => {
                if (response.data[key].hasOwnProperty(key + '_' + s.aaa004)) {
                  s[key] = response.data[key][key + '_' + s.aaa004] || '';
                  s[key + '_edit'] = true;
                } else {
                  s[key + '_edit'] = false;
                  s[key] = '';
                }
              })
            }
            console.log(this.masterList, this.masterColumnDefs);
            this.masterListInitData = JSON.parse(JSON.stringify(this.masterList));
          } else {
            this.dwMessage.warning(response.message)
          }
        },
        error => {
        },
        () => {
        }
      );
    })
  }

  // 主表选择变更事件
  onMaserSelectionChanged(event: any): void {

  }


  // 设定RowNodeID
  getRowNodeId(data: any): number {
    return data.id;
  }


  onMasterRowClicked(event: any): void {

  }

  // 开启编辑
  changeStatus() {
    if (this.gState == 'browse') {
      this.gState = 'edit';
      this.masterGridOptions.suppressClickEdit = false;
    } else {
      this.gState = 'browse';
      this.masterGridOptions.suppressClickEdit = true;
      this.masterList = JSON.parse(JSON.stringify(this.masterListInitData))
    }
  }

}

