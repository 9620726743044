import { DatePipe } from '@angular/common';
import {Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AbstractControl, AsyncValidatorFn, FormArray, FormGroup, ValidationErrors} from '@angular/forms';
import { DwRoutingMessageService } from '@webdpt/framework';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-cell-input-date-editor',
  templateUrl: './cell-input-date-editor.component.html',
  styleUrls: ['./cell-input-date-editor.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class CellInputDateEditorComponent {

  formGroup: FormGroup;
  private colId: string;
  private rowId: number;
  fParam: any;
  initValue: any;
  @ViewChild('cellText') cellText: ElementRef;
  dateFormat = 'yyyy/MM/dd';

  constructor(private fxUserService: FxUserInfoService, private datePipe: DatePipe, public dwMessage: DwRoutingMessageService) {
  }

  agInit(params: any) {
    this.colId = params.column.colId;
    this.rowId = params.node.id;
    this.fParam = params;

    const index = params.context.serviceObj.indexMap.get(this.rowId);
    const cellFromGroup = ((params.context.myFormArray as FormArray).at(index) as FormGroup);
    this.formGroup = cellFromGroup;

    var startValue;
    // cellStartedEdit is only false if we are doing fullRow editing
    if (params.cellStartedEdit) {
        var keyPressBackspaceOrDelete = params.keyPress === 8
            || params.keyPress === 46;
        if (keyPressBackspaceOrDelete) {
            startValue = '';
        }
        else if (params.charPress) {
            startValue = params.charPress;
        }
        else {
            startValue = params.value;
        }
    }
    if (startValue !== null && startValue !==  undefined && startValue !== '') {
      this.formGroup.get(this.colId).setValue(startValue);
    }
  }

  afterGuiAttached() {
    this.cellText.nativeElement.focus(); 

  }

  // refresh(params: any): boolean {
  //   console.log('* * * form cell refresh fn');
  //   const gridFormArray = params?.context.gridFormArray;
  //   const rowFromGroup = gridFormArray.at(params.rowIndex);
  //   const cellFormControl = rowFromGroup.get(this.key);
  //   // this.formGroup = params.context.formGroup.controls[this.rowId];
  //   this.formGroup = rowFromGroup;
  //   // this could also be done in GridComponent.createFormControls, but the cell component could be doing something with
  //   // the value, so it feels more natural that the control value be set here
  //   // this.formGroup.at(this.key).patchValue(this.value);
  //   // this.formGroup.get(this.key).patchValue(this.value);
  //   return true;
  // }

  // getValue(): any {
  //   // return this.value;
  //   console.log('getValue');
  //   return this.formGroup.get(this.colId).value;
  // }

  getValue(): any {
    console.log('getValue')
    var value = this.formGroup.get(this.colId).value;
    if (!value || value === 'Invalid Date') {
      this.formGroup.get(this.colId).setValue('');
      return '';
    } else {
      const formValue = this.datePipe.transform(value, this.dateFormat);
      this.formGroup.get(this.colId).setValue(formValue);
      return formValue;
    }
  }

  public onBlur = (event: any): any => {
    console.log(event);
    console.log(this.formGroup);
    if (this.formGroup.get(this.colId).value === this.initValue) {return}
    var value = this.formGroup.get(this.colId).value;
    if (value === undefined || value === null || value === NaN || value === 'Invalid Date') {
      value = '';
      this.formGroup.get(this.colId).setValue('');
    } else {
      var res = /^\d{4}(\/|-|\.|年)\d{1,2}(\/|-|\.|月)\d{1,2}(日|)/;
      if (!res.test(value)) {
        this.formGroup.get(this.colId).setValue('');
        this.dwMessage.error('请输入正确的日期格式yyyy/MM/dd');
      } else {
        value = value.replace(/日/g, '');
        value = value.replace(/年|月|\./g, '/');
        console.log('格式化', value);
        value = new Date(value);
        const formValue = this.datePipe.transform(value, this.dateFormat);
        this.formGroup.get(this.colId).setValue(formValue);
        console.log('格式化date', value);
      }
    }
    try {
      if ((this.fParam as any).onBlurFn) {
        (this.fParam as any).onBlurFn({
          value: this.formGroup.get(this.colId).value,
          id: this.rowId,
          colId: this.colId
        });
      }
      
    } catch (ex) {
      console.error(ex);
    }
  }

  // //change事件  dw-input封装过离开时被触发
  // public onChange(event): void {
  //   console.log('onChange', event);
  //   var value = this.formGroup.get(this.colId).value;
  //   if (value === undefined || value === null || value === NaN || value === 'Invalid Date') {
  //     value = '';
  //     this.formGroup.get(this.colId).setValue('');
  //   } else {
  //     var res = '/^\d{4}(/|-|年|)\d{1,2}(/|-|月|)\d{1,2}(日|)/'
  //   }
  //   this.onBlur(event);
  // }


  public onKeyDown(event: KeyboardEvent): void { //键盘事件
    if (event.keyCode === 27) {
      event.stopPropagation();
    }
    // if (event.keyCode === 13 || event.keyCode === 27 || event.keyCode === 37 || event.keyCode === 38
    //   || event.keyCode === 39 || event.keyCode === 40) {
    //   event.stopPropagation();
    // }
    // var isNavigationKey = event.keyCode === 37
    //       || event.keyCode === 39
    //       || event.keyCode === 38
    //       || event.keyCode === 40
    //       || event.keyCode === 34
    //       || event.keyCode === 33
    //       || event.keyCode === 36
    //       || event.keyCode === 35;
    //   if (isNavigationKey) {
    //       // this stops the grid from executing keyboard navigation
    //       event.stopPropagation();
    //       // this stops the browser from scrolling up / down
    //       var pageUp = event.keyCode === 33;
    //       var pageDown = event.keyCode === 34;
    //       if (pageUp || pageDown) {
    //           event.preventDefault();
    //       }
    //   }
    
  }

    //获取焦点
    focusIn(event?: any): void {
      console.log(event);   
      this.initValue = this.formGroup.get(this.colId).value;
      this.cellText.nativeElement.focus(); 
    }
  
    //离开焦点
    focusOut(event?: any): void {
      console.log('离开焦点', event);
      this.cellText.nativeElement.blur();
    }

    checkField(): AsyncValidatorFn {
      return (control: AbstractControl): Observable<ValidationErrors | null> => {
        const val = control.value;
        return Observable.create((observer) => {
          setTimeout(() => {
            if (val === this.initValue) {
              observer.next(null);
            } else{
              observer.next({
                '校验成功': true
              });
            }
            observer.complete();
          }, 300);
        });
    
      };
    }

    isCancelBeforeStart(): boolean {
      console.log('isCancelBeforeStart')
      return false;
    }

    isCancelAfterEnd(): boolean {
      console.log('isCancelAfterEnd')
      // var value = this.formGroup.get(this.colId).value;
      // if (value !== 'Invalid Date' && value) {
      //   value = new Date(this.datePipe.transform(value, this.dateFormat));
      // } else {
      //   value = null;
      // }
      
      this.onBlur('');
      return false;
    }

    destroy() {
      console.log('destroy')
    }
}
