import { DwI18nService } from 'ng-quicksilver';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, Subject, ConnectableObservable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { filter, distinctUntilChanged, map, catchError, publishLast } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as QuicksilverModule from 'ng-quicksilver';

import { LocalStorage } from '../../../storage/local-storage';
import { DwLanguageLoaderService } from './language-loader.service';
import { DwViewLangLoaderService } from './view-lang-loader.service';
import { DwLanguageListService } from './language-list.service';
import { IDwLanguageList } from '../interface/language.interface';
import { DwAppTitleService } from '../../title/app-title.service';


/**
 * 動態翻譯文字 ( 需先擁有"對應已翻譯完成文字"，不然將返回傳入文字 )
  * 作業自行使用 this.translateService.instant('翻譯內容'); / this.translateService.stream('翻譯內容');
  * 設定語言別
  * DwLanguageService.setUp(language: string);
  * 訂閱語言變更
  * DwLanguageService.language
 * 訂閱默认語言
  * DwLanguageService.getDefaultLang
 */

@Injectable()
export class DwLanguageService implements Resolve<any> {

  private languageSubject: BehaviorSubject<string>; // 語言主題
  private languagesList: IDwLanguageList[];
  private lang: Array<any> = []; // 曾翻譯過的文件檔名

  constructor(
    private dwI18nService: DwI18nService,
    private localStorage: LocalStorage,
    private translateService: TranslateService,
    private languageListService: DwLanguageListService,
    private languageLoaderService: DwLanguageLoaderService,
    private viewLangLoaderService: DwViewLangLoaderService,
    private appTitleService: DwAppTitleService
  ) {
    this.languageSubject = new BehaviorSubject<string>(null);

    this.languagesList = [];
    this.languageListService.getLanguagesList().subscribe(
      response => {
        if (Array.isArray(response)) {
          this.languagesList = response;
        } else {
          this.languagesList = [];
        }
      }
    );

    this.init();

    // 執行全部的翻譯檔載入器
    this.language$.subscribe(
      lang => {
        this.languageLoaderService.getTranslation(lang);
      },
      error => {
        console.log(error);
      }
    );
  }

  /**
   * 設定預設語言別
   */
  setDefaultLang(lang: string): void {
    let language = lang;

    if (!language) {
      language = this.getBrowserCultureLang;
    }

    // 檢查語言別是否為可用語言
    let check = false;
    this.languagesList.every(
      (item: IDwLanguageList) => {
        if (item.value === language) {
          check = true;
          return false;
        } else if (item.value.indexOf(language) != -1) {
          check = true;
          language = item.value;
          return false;
        }else {
          return true;
        }
      }
    );

    if (!check && this.languagesList[0]) {
      language = this.languagesList[0].value;
    }

    this.translateService.setDefaultLang(language);
  }

  /**
   * 取得預設語言別
   */
  getDefaultLang(): string {
    const language = this.translateService.getDefaultLang();
    return language;
  }

  /**
   * 設定語言別
   */
  setUp(language: any): void {
    if (!language) {
      return;
    }
    this.translateService.use(language);
    this.localStorage.set('fx.language', language);
    if (this.lang.length > 0) {
      this.multipleFiles(language, this.lang);
    }
    this.dwI18nService.setLocale(QuicksilverModule[language]); // 本身國際化
    this.languageSubject.next(language);
    this.appTitleService.setTitle(language);
  }

  /**
   * 訂閱 porgramID 並提取翻譯文件
   */
  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const
      recorded = [],
      programIdList = route.data.dwRouteData;
      // 自定义单据公共作业的programId需要额外取
      if (programIdList.dwAuthId == 'fxad004' && route.queryParams && route.queryParams.programId) {
        programIdList.programId = route.queryParams.programId;
      }
      programIdList.i18n = route.data.i18n;
    let
      language = this.currentLanguage;
    if (!programIdList.programId) {
      return null;
    } else {
      let languageLoaderObs$ = (this.getlanguageLoaderObs(recorded, programIdList, language)).pipe(publishLast());
      (languageLoaderObs$ as ConnectableObservable<any>).connect();
      return languageLoaderObs$;
    }
    
  }

  getlanguageLoaderObs(recorded, programIdList, language): Observable<any> {
    
    // 登出時，語言別如果被刪除，需要重新初始化語言別，因為畫面翻譯檔載入器不限已登入
    if (!language) {
      this.init();
      language = this.currentLanguage;
    }
    // if (programIdList.programId && !programIdList.i18n) {
    //   this.lang.push(programIdList.programId);
    //   return this.singleFile(language, programIdList.programId);
    //   // const languageLoaderObs = this.singleFile(language, programIdList.programId);
    // } 
    // else if (programIdList.programId && programIdList.i18n && programIdList.i18n.length === 0) {
    //   this.lang.push(programIdList.programId);
    //   return this.singleFile(language, programIdList.programId);
    //   // const languageLoaderObs = this.singleFile(language, programIdList.programId);
    // } else
    // if (programIdList.programId && programIdList.i18n && toString.call(programIdList.i18n) === '[object Object]') {
    //   this.translateService.setTranslation(language, programIdList.i18n, true);
    //   return this.singleFile(language, programIdList.programId);
    //   // const languageLoaderObs = this.singleFile(language, programIdList.programId);
    // } else
    if (programIdList.programId) {
      recorded.push('common');
      recorded.push(programIdList.programId);
      if (programIdList.i18n && programIdList.i18n.length > 0) {
        programIdList.i18n.forEach(obj => {
          if (recorded.filter((item) => {return item == obj}).length < 1) {
            recorded.push(obj);
          }
        });
      }
      // recorded.push('common');
      // const data = recorded.concat(programIdList.programId);
      const data = recorded;
      this.lang = data;
      return this.multipleFiles(language, data);
      // const languageLoaderObs = this.multipleFiles(language, data);
    }
    //  else
    // if (programIdList.i18n.default && programIdList.i18n.default.length > 0) {
    //   recorded.push(programIdList.i18n.default);
    //   const data = recorded.concat(programIdList.programId);
    //   this.lang = data;
    //   return this.multipleFiles(language, data);
    //   // const languageLoaderObs = this.multipleFiles(language, data);
    // }
  }

  /*
  * 連帶i18n
  */
  private multipleFiles(language: string, program: any): Observable<any> {
    const result: any = {};
    
    for (const id in program) {
      if (Number(id) + 1 === program.length) {
        return this.viewLangLoaderService.getTranslation(language, program[id]).pipe(
          map(
            element => {
              this.translateService.setTranslation(language, element, true)
            }
          ),
          catchError(
            (error: any) => {
              return ('Failed to get translation file：' + error.message || error);
            }
          )
        );
      } else {
        this.viewLangLoaderService.getTranslation(language, program[id]).pipe(
          map(
            element => {
              return Object.assign(result, element)
            }
          ),
          catchError(
            (error: any) => {
              return ('Failed to get translation file：' + error.message || error);
            }
          )
        ).subscribe(
          response => {
            return this.translateService.setTranslation(language, response, true)
          }

        );
      }
    }

    
  }

  /*
   * 僅有 programId
   */
  private singleFile(language: string, programId: string): Observable<any> {
    return this.viewLangLoaderService.getTranslation(language, programId).pipe(
      map(
        langData => {
          this.translateService.setTranslation(language, langData, true);
        }
      ),
      catchError(
        (error: any) => {
          return ('Failed to get translation file：' + error.message || error);
        }
      )
    );
  }

  /**
   * 取得瀏覽器語言別
   * navigator.language 在Chrome 版本 68.0.3440.106 有bug,都是取到第一個語言別,無法取到介面所使用的語言
   * https://bugs.chromium.org/p/chromium/issues/detail?id=802006
   * https://zzz.buzz/2016/01/13/detect-browser-language-in-javascript/#behavior-in-ie11
   */
  get getBrowserCultureLang(): string {
    return this.translateService.getBrowserCultureLang().replace(new RegExp(/-/g), '_');
  }

  /**
   * 取得目前語言別
   */
  get currentLanguage(): string {
    const language = this.localStorage.get('fx.language');
    return language;
  }

  /**
   * 取得語言別
   */
  get language$(): Observable<string> {
    return this.languageSubject.asObservable().pipe(
      filter(obsData => obsData !== null), // 不廣播初始值
      distinctUntilChanged() // 有改變時才廣播
    );
  }

  init(): void {
    // 语言别意外丢失处理
    this.setDefaultLang(undefined);
    let language = this.getDefaultLang();

    if (this.currentLanguage) {
      language = this.currentLanguage;
    }

    this.setUp(language);
  }
}
