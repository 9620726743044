import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UploadFile, UploadXHRArgs } from 'ng-quicksilver';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { DwRoutingMessageService } from '@webdpt/framework';
import { UploadFilter } from 'ng-quicksilver';

@Component({
  selector: 'app-file-upload',
  template: 
  `
  <dw-upload
    dwListType="text"
    [(dwFileList)]="_fileList"
    [dwCustomRequest]="customReqFile" 
    (dwChange)="changeFile($event)"
    [dwDisabled] = "_disabled"
    [dwMultiple] = true
    [dwShowButton] = "_showButton"
    [dwPreview] = "handlePreview"
    [dwFilter]="filters"
    >
    <button dw-button>
      <i class="anticon anticon-upload"></i><span>{{btnTitle}}</span>
    </button>
  </dw-upload>
  
  `,
  styles: [
    `
    :host ::ng-deep .customize-footer {
      border-top: 1px solid #e9e9e9;
      padding: 10px 18px 0 10px;
      text-align: right;
      border-radius: 0 0 0px 0px;
      margin: 15px -16px -5px -16px;
    }
    `
  ]
})
export class FileUploadComponent implements OnInit  {
  // [dwPreview]="handlePreview"
  //   [dwRemove]="handleRemove"
  // [dwShowUploadList] ="showUploadList"
  // <dw-modal [dwVisible]="previewVisible" [dwContent]="modalContent" [dwFooter]="2019-10-17" (dwOnCancel)="previewVisible=false">
  //   <ng-template #modalContent >
  //     <img [src]="previewImage" [ngStyle]="{ 'width': '100%' }" />
  //   </ng-template>
    
  // </dw-modal>
      // [dwFileType] = "image/png,image/jpeg,image/gif,image/bmp"
  //   <ng-template #modalContent *ngIf="filetype == 'mp4'">
  //   <video width="320" height="240" controls>
  //   <source src="movie.mp4" type="video/mp4">
  //   <source src="movie.ogg" type="video/ogg">
  //   您的浏览器不支持 HTML5 video 标签。
  // </video>
  // </ng-template>
  // public picFileOption: any;
  // <video width="320" height="240" controls>
  //         <source [src]="previewImage" type="video/mp4">
  //         您的浏览器不支持 video 标签。
  //     </video>
  _fileList = [];
  // filetype = '';
  // previewImage = '';
  // previewVisible = false;
  _disabled = false;
  _isgetData = true;
  _showButton = true;
  // showUploadList = {
  //   showPreviewIcon: true, 
  //   showRemoveIcon: true 
  // }
  // filetypelimit = 'image/png,image/jpeg,image/gif,image/bmp';
  // videoUrl = "http://172.16.101.7:8888/group1/M00/00/05/rBBlB1237LyAJ-0SABsepY_1WwM884.mp4?filename=wx_camera_1571148719623.mp4&ts=1572334780&token=4800157e19d6060ea7b85e23536b0d29"
  @Input() limit: number = 0; //限制上传图片个数
  @Input() btnTitle: string = 'upload';
  public _picFileOption: any;
  @Input() set picFileOption(_picFileOption: any) {
    this._picFileOption = _picFileOption;
  }
  // @Input() isgetData: boolean =  true; //是否自动获取数据库数据
  @Input() set isgetData(value: boolean) {
    this._isgetData = value;
  }
  // @Input() fileList: any = []; //图片列表
  @Input() set fileList(value: any) {
    this.fileListChange.emit(value);
    this._fileList = value;
  }
  @Output() fileListChange = new EventEmitter();
  @Input() set disabled(disabled: boolean) { //禁用图片编辑
    this._disabled = disabled;
  }
  // @Input() showButton: boolean = true; //是否显示上传按钮
  @Input() set showButton(value: boolean) {
    this._showButton = value;
    // this.showUploadList.showRemoveIcon = this._showButton;
  }
  

  constructor(
    public http: FxDapHttpClient,
    public dwMessage: DwRoutingMessageService,) {
    }

  ngOnInit(): void {

    if (this._isgetData) {
      this.getFileListdata();
    } 
    // else {
    //   this._fileList = this.fileList;
    // }
    if (this.limit > 0 ) {
      this._disabled = this._fileList.length == this.limit &&this.limit !== 0 ? true : false;
    }
  }

  getFileListdata(): void {
    if (this._picFileOption.aay001 && this._picFileOption.aay002 && this._picFileOption.aay003) {
      this.http.post('A/IACommonService/pub_get_aay', { param: this._picFileOption}).subscribe(
        response => {
          if (response.success && response.data) {
            console.log('图片输出', response)
            this._fileList = response.data;
            this.fileList = response.data.filter(item => item.aay010 == '2');
          }
        });
    } else {
      this._fileList = [];
      this.fileList= this._fileList;
    }
  }  

  public handlePreview = (file: UploadFile) => {
    console.log('图片预览')
    window.open(file.url);
  }

  // public handleRemove(file): boolean{
  //   const flag = true;
  //   return flag;
  // }

  //上传图片
  customReqFile = (item: UploadXHRArgs) => {
    console.log('[item]',item);
    // 构建一个 FormData 对象，用于存储文件或其他参数
    const formData = new FormData();
    formData.append('attach', item.file as any);
    this.http.post('A/IAttachFileService/uploadFile', formData).subscribe(response => {
      if (response.successValue) {
        console.log('response',response);
        console.log('file_list',this._fileList);
        item.onSuccess!(response.data, item.file!, '');
      } else {
        this.dwMessage.errorNew(response.message, response.isRemind, response.programNo, response.programName);
      }
      console.log(this._fileList.length);
      // if (this.limit > 0) {
      //   this._disabled = this._fileList.length == this.limit &&this.limit !== 0 ? true : false;
      // }
    });
  }
  //格式化图片信息
  changeFile(file): void{
    //console.log('[dwRemove]',file);
    if(file.type == 'success'){
      var fileData = [];
      file.file.status = 'done';
      for(var i= 0;i< file.fileList.length;i++){
        if(file.fileList[i].uid == file.file.uid){
          file.fileList[i].id = file.file.response[0].id;
          file.fileList[i].url = file.file.response[0].url;
          file.fileList[i].fileSize = file.file.response[0].fileSize;
          file.fileList[i].fileType = file.file.response[0].fileType;
          file.fileList[i].name = file.file.response[0].fileName;
          file.fileList[i].uploadDate = file.file.response[0].uploadDate;
          file.fileList[i].status = 'done';
          file.fileList[i].aay009 = 'N';
          file.fileList[i].aaystus = 'N';
          file.fileList[i].aay010 = '2';
          file.thumbUrl = '';
        }
      }
    }
    this._fileList = file.fileList;
    this._fileList.forEach(element => {
      element.response = [];
      element.thumbUrl = '';
    });
    if (this.limit > 0 ) {
      this._disabled = this._fileList.length == this.limit &&this.limit !== 0 ? true : false;
    }
  }
  filters: UploadFilter[] = [
    {
      name: 'size',
      fn: (fileList: UploadFile[]) => {
        const filterFiles = fileList.filter(w => Number(w.size)<31457280);//1M=1024kb 30M=30720kb
        if (filterFiles.length !== fileList.length) {
          this.dwMessage.error(`文件上传大小，单个文件不能超过30M！`);
          return filterFiles;
        }
        return fileList;
      }
    }
  ];

  public getFileList(): any {
    this._fileList.forEach(element => {
      element.response = [];
      element.thumbUrl = '';
    });
    this.fileList = this._fileList.map(function (obj) {
      return Object.assign({response: [], thumbUrl: ''}, obj);
    })
    return this._fileList;
  }
}