import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DwRoutingMessageService, DwUserService } from '@webdpt/framework';
import { DwModalService, P } from 'ng-quicksilver';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, fromEvent } from 'rxjs';
import { FxDapHttpClient } from '../../fx-http-client';
import { debounceTime } from 'rxjs/operators';
import { start } from 'repl';
import { FxLangModalComponent } from '../fx-lang/fx-lang.component';

@Component({
  selector: 'fx-tool-bar',
  templateUrl: './fx-tool-bar.component.html',
  styleUrls: ['./fx-tool-bar.component.less']
})
export class FxToolBarComponent implements OnInit {
  @Input() programId: string = '';
  @Input() btnData: any[] = []; //按钮资料\
  @Input() exportData: any = {};   // 导出资料
  @Input() downloadData: any[] = []; // 模板下载资料
  @Input() importData: any = {};   // 导入资料
  @Input() warningMsg: any; // 导入后提示信息
  @Input() checkBtn: Boolean = true; //是否需要检查按钮权限，EX编辑页面新增修改等无需检查权限
  @Input() editData: any = {}; // 多语言设置需要修改的数据
  btnList$: Observable<Array<{
    icon: string,
    type: string,
    id: string,
    name: string,
    disabled: () => boolean
  }>>;
  @Output() onButton = new EventEmitter<boolean>();
  @Output() onReload = new EventEmitter<boolean>();
  @ViewChild('btn') btnW: ElementRef; //绑定按钮栏位的节点
  @ViewChild('btns') btns: ElementRef;
  public currentStyles = {}; //浮层按钮的样式
  public showBtn = false; //更多按钮显示层
  public btnMore: any[] = []; // 更多按钮
  public showMoreBtn = false;
  // public btnGroup = fromEvent(document,'resize')
  public autoWidth = '525px'; //记录默认可见宽
  public isChangeData: any = [];
  public winChange;
  public isSplit = false; //替换数组显示隐藏
  public newBtnList: any = [];//待替换数组 //新展示btn
  public btnExportListShow = false; // 是否展示导出列表
  public visible = false;
  public downloadItem: any = null; // 模板下载item
  public programRouteId: any; // 记录作业的作业编码+路由id
  private FTemplateData: any; //下载模板返参
  private FShowbtnData: any; //按钮返参
  private FProgramId: string = ''; //当前作业id


  constructor(
    public dwMessage: DwRoutingMessageService,
    public http: FxDapHttpClient,
    private translateService: TranslateService,
    public dwModalService: DwModalService,
  ) {
  }

  ngOnInit() {
    // 记录当前作业编号+路由id确定唯一性-start
    const programUrl = window.location.href;
    this.programRouteId = (programUrl.split('/dwTabRouting/') && programUrl.split('/dwTabRouting/').length) ? programUrl.split('/dwTabRouting/')[programUrl.split('/dwTabRouting/').length - 1] : '';
    // 记录当前作业编号+路由id确定唯一性-end

    //console.log(this.btnW.nativeElement.parentNode.parentNode,this.btnW.nativeElement.parentNode.parentNode.clientWidth,'btnW$$')
    this.btnList$ = this.updateButtonList();
    this.btnList$.subscribe(data => {
      console.log(data);
      // this.updateButtonList().subscribe(data => {
      //第一次赋值      
      // data = this.getGroupBtns(data);// 按钮分组显示
      this.isChangeData = data;
      //获取宽度值
      //let param = Math.round(Math.round((document.body.clientWidth - (200 + 10 + 20))/3) + (Math.round((document.body.clientWidth - (200 + 10 + 20))/3)/3)*2);  
      const getCurrentW$ = this.btnW.nativeElement.parentNode.parentNode.clientWidth;
      //console.log(getCurrentW$,'获取宽度值')
      this.changeBtnWidth(getCurrentW$, data);
    })

    // 页面监听
    this.winChange = fromEvent(window, 'resize')
      .pipe(debounceTime(300))
      .subscribe(event => {
        // 解决所有作业共用一个window，导致监听会导致其他作业tool-bar按钮展示到更多里面的问题-start
        var currentProgramUrl = window.location.href, currentProgramRouteId;
        if (currentProgramUrl) {  // 获取当前打开页面作业编号+路由id
          currentProgramRouteId = (currentProgramUrl.split('/dwTabRouting/') && currentProgramUrl.split('/dwTabRouting/').length) ? currentProgramUrl.split('/dwTabRouting/')[currentProgramUrl.split('/dwTabRouting/').length - 1] : '';
        }
        if (this.programRouteId !== currentProgramRouteId) {
          return;
        }
        // 解决所有作业共用一个window，导致监听会导致其他作业tool-bar按钮展示到更多里面的问题-end
        let clWidth = document.body.clientWidth; //屏幕宽度
        // let param = Math.round(Math.round((clWidth - (200 + 10 + 20))/3) + (Math.round((clWidth - (200 + 10 + 20))/3)/3)*2);  
        const getReW$ = this.btnW.nativeElement.parentNode.parentNode.clientWidth; //按钮元件占用宽度
        console.log(getReW$, 'getReW$')
        this.changeBtnWidth(getReW$, this.isChangeData);
      });
  }

  changeBtnWidth($W: any, $D: any) { //$W 可分配宽度   $D 按钮清单
    //$W不允许为空
    if ($W == '0' || $W == '') {
      return;
    }
    try {
      var sumlength = 70;
      var btnArr = [].concat($D);
      $D.forEach((obj, index) => {
        sumlength = sumlength + obj.btn_len;
        if (sumlength > $W) {
          this.showMoreBtn = true;
          this.isSplit = true; //显示备用数组
          this.newBtnList = btnArr.splice(0, index);  //新的展示按钮清单
          this.btnMore = btnArr; //新的更多按钮清单
          throw new Error("split break");
        } else {
          this.showMoreBtn = false;
        }
      });
    } catch (error) {

    } finally {
      if (!this.showMoreBtn) { //没有更多按钮
        this.newBtnList = btnArr;
      }
      if (this.btnMore.length > 0) {
        this.btnMore.forEach((item, key) => {
          item.order = key;
        });
        const downloadIdx = this.btnMore.findIndex(item => {
          return item.id == 'btnTDownload';
        });
        if (downloadIdx !== -1) {
          this.downloadItem = this.btnMore[downloadIdx];
          this.btnMore.splice(downloadIdx, 1);
        } else {
          this.downloadItem = null;
        }
      }
    }
  }

  //获取作业满足条件的按钮清单(包含导入导出等按钮模板)
  updateButtonList(): Observable<any> {
    return Observable.create(observer => {
      if (!this.checkBtn) {
        observer.next(this.btnData);
        observer.complete();
      } else {
        this.getAuthedButtonList(this.programId).then(lstBtn => {
          // modify by quwj--调整按钮隐藏功能
          const result = this.btnData.map(item => {
            item.btn_len = (item.name ? item.name.length : 2) * 12 + 34;
            const index = lstBtn.findIndex(btn => btn.id === item.id);
            return index < 0
              ? item
              : Object.assign(item, {
                authed: true,
                icon: lstBtn[index].icon,
                name: lstBtn[index].name,
                btn_len: (lstBtn[index].name ? lstBtn[index].name.length : 2) * 12 + 34
              });
          });
          // btnLang多语言按钮需要额外处理，不根据作业设置只根据权限是否设置显示
          var data = result.filter(item => item.authed && item.id != 'btnLang'); //有权限且作业需要显示按钮清单（btnLang多语言按钮除外）
          const idx = lstBtn.findIndex(s => {
            return s.id == 'btnLang';
          });
          if (idx !== -1) {
            const resIdx = data.findIndex(s => {
              return s.id == 'btnLang';
            });
            if (resIdx === -1) {
              data.push(Object.assign(lstBtn[idx], {
                authed: true,
                icon: lstBtn[idx].icon,
                name: lstBtn[idx].name,
                aaj003: lstBtn[idx].aaj003,
                btn_len: (lstBtn[idx].name ? lstBtn[idx].name.length : 2) * 12 + 40
              }))
            }
          }
          var isHasImport = data.filter(item => item.id == 'btnImport' || item.id == 'btnCusImport' || item.id == 'btnExport' || item.id == 'btnSelfExport' || item.id == 'btnTDownload').length > 0;
          if (isHasImport) { //获取导入导出等模板
            // 获取模板
            if (this.FProgramId != this.programId || !this.FTemplateData) { //作业编号改变或返回模板为空
              this.http.post('A/IFxai014Service/getTemplateList', { param: { program_no: this.programId } }, { headers: { 'program-code': this.programId } }).subscribe(tempResponse => {
                if (tempResponse.success && tempResponse.data && tempResponse.data.length) {
                  this.FProgramId = this.programId;
                  this.FTemplateData = tempResponse.data;
                  data.forEach(btnItem => {
                    var templateArr = tempResponse.data.filter(tmp => {
                      return tmp.abx004 == btnItem.id && tmp.abx002 == this.programId;
                    });
                    if (templateArr.length) {// 如果有模板则为导入按钮
                      btnItem.importTemplateList = templateArr;
                      btnItem.isImportBtn = true;
                    } else {
                      btnItem.importTemplateList = [];
                    }
                  });
                  data = this.getGroupBtns(data);// 按钮分组显示
                  observer.next(data);
                  observer.complete();
                } else {
                  data = this.getGroupBtns(data);// 按钮分组显示
                  observer.next(data);
                  observer.complete();
                }
              });
            } else {
              this.FProgramId = this.programId;
              data.forEach(btnItem => {
                var templateArr = this.FTemplateData.filter(tmp => {
                  return tmp.abx004 == btnItem.id && tmp.abx002 == this.programId;
                });
                if (templateArr.length) {// 如果有模板则为导入按钮
                  btnItem.importTemplateList = templateArr;
                  btnItem.isImportBtn = true;
                } else {
                  btnItem.importTemplateList = [];
                }
              });
              data = this.getGroupBtns(data);// 按钮分组显示
              observer.next(data);
              observer.complete();
            }
          } else {
            data = this.getGroupBtns(data);// 按钮分组显示
            observer.next(data);
            observer.complete();
          }
        });
      }
    });
  }

  // 权限按钮分组
  public getGroupBtns(btnList) {
    let groupList = [];// 存放分组后的按钮
    btnList.forEach((s, index) => {
      if (!s.aaj003) {
        groupList.push(s);// 如果未分组直接单独显示
      } else {// 如果分组，则只单独显示一笔分组按钮，明细放到分组里面         
        const idx = groupList.findIndex(t => {
          return t.aaj003 == s.aaj003;
        });
        if (idx === -1) {
          groupList.push({
            id: s.aaj003,
            name: s.aaj003,
            aaj003: s.aaj003,
            icon: null,
            btnList: [s],
            btn_len: (s.aaj003 ? s.aaj003.length : 2) * 12 + 32
          });
        } else {
          groupList[idx].btnList.push(s);
        }
      }
    });
    return groupList;
  }


  //获取有权限按钮清单
  async getAuthedButtonList(programId: string): Promise<any> {
    try {
      if (this.FProgramId != this.programId || (!this.FShowbtnData && this.programId)) { //作业编号改变或返回模板为空
        const response = await this.http.post('A/IACommonService/showButtonDisplay', { param: { program_no: programId } }, { headers: { 'program-code': programId } }).toPromise();
        if (response.success && response.data) {
          // quwjtest start
          // response.data.forEach((s, index) => {
          //   if (index == 0 || index == 1) {
          //     s.aaj003 = '分组一';
          //   } else if (index == 2 || index == 3 || index == 4 || index == 5 || index == 6 || index == 7) {
          //     s.aaj003 = '组二';
          //   } else if (index > 20) {
          //     s.aaj003 = '分组测试';
          //   } else {
          //     s.aaj003 = ''
          //   }
          // })
          // quwjteset end
          this.FProgramId = this.programId;
          this.FShowbtnData = response.data;
          return response.data.map(item => {
            return item.icon
              ? Object.assign({}, item, { icon: 'anticon ' + item.icon })
              : item;
          });
        } else {
          return [];
        }
      } else {
        this.FProgramId = this.programId;
        return this.FShowbtnData.map(item => {
          return item.icon
            ? Object.assign({}, item, { icon: 'anticon ' + item.icon })
            : item;
        });
      }
    } catch (e) {
      return [];
    }
  }

  //对应的按钮操作
  clickFixedBtn(name: any, item?: any): void {
    this.visible = false;
    if (name === 'btnExport') {
      this.btnExportListShow = true; // 显示导出列表
    } else {
      // 多语言按钮特殊处理
      if (name == 'btnLang') {
        if (Object.keys(this.editData).length === 0) {
          this.dwMessage.warning(this.translateService.instant('comm-请选择一条需要操作的数据'));
          return;
        }
        const modal = this.dwModalService.create({
          dwTitle: this.translateService.instant('维护多语言'),
          dwStyle: { top: '20px',left:'30%' },
          dwMaskClosable: false, // 點擊遮罩是否允許關閉
          dwContent: FxLangModalComponent,
          dwComponentParams: {
            programId: this.programId,
            data: this.editData
          },
          dwWidth: window.screen.width * 0.5,
          dwWrapClassName: 'fx_md_modal',
          dwClosable: false,
          dwClassName: '',
          dwFooter: [
            {
              label: '取消',
              shape: 'default',
              onClick: (data: any): any => {
                modal.destroy();

              }
            },
            {
              label: '确定',
              type: 'primary',
              onClick: (data: any): any => {
                modal.close({});
                if (data.gState == 'edit') {
                  data.masterGridApi.stopEditing();
                  let editInfo = {};
                  data.masterList.forEach(s => {
                    for(var key in data.fieldObj) {
                      if (s[key + '_edit']) {
                        editInfo[key + '_' + s.aaa004] = s[key]
                      }
                    }
                  })
                  const param = {
                    tableKeyInfo: this.editData,
                    i18nInfo: editInfo
                  }
                  // 更新数据
                  this.http.post('A/Ii18nService/updateI18NInfo', { param: param }).subscribe(
                    response => {
                      if (response.success) {
                        this.dwMessage.success(this.translateService.instant('comm-modifysuccess'))
                      } else {
                        this.dwMessage.warning(response.message)
                      }
                    },
                    error => {
                    },
                    () => {
                    }
                  );
                }
              }
            }
          ]
        });
      } else {
        this.onButton.emit(name);
      }
    }
  }

  public checkActionDataAurhority(action: string, data: any, table?: string): Observable<boolean> {
    const postParam = {
      action_list: [action],
      primaryKey_value_map: data,
      program_no: this.programId,
      table_no: table || '',
    };
    return Observable.create(observer => {
      this.http.post('A/IACommonService/chkActionDataAuthority', { param: postParam }).subscribe(
        response => {
          // if (response.success && response.data && response.data.find(item => item === action)) {
          observer.next(response.success && response.data && response.data.find(item => item === action));

          // } else
        },
        error => {
          observer.next(false);
        },
        () => {
          observer.complete();
        }
      );
    });
  }

  // 导入完成重新查询服务
  reloadData(response): void {
    console.log(response);
    this.onReload.emit(response);
  }

}
