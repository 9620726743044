import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {ICellEditorParams} from 'ag-grid-community';
import {CfcNumberFormatRendererComponent} from '../cfc-number-format-renderer.component';
import {FxUserInfoService} from '../../../../auth/fx-auth/fx-user-info.service';
import {takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';
import {CellValidationEditorComponent} from '../cell-validation-editor/cell-validation-editor.component';
import {cloneAbstractControl} from '../../../services/common-method';

@Component({
  selector: 'app-cell-number-format-editor',
  templateUrl: './cell-number-format-editor.component.html',
  styleUrls: ['./cell-number-format-editor.component.less']
})
export class CellNumberFormatEditorComponent extends CellValidationEditorComponent implements ICellEditorAngularComp, OnDestroy {
  rowFormGroup: FormGroup;
  key;
  columnName: string;
  rowId: number;

  // 日期输入框
  formControl: FormControl;

  // colId;
  name: string;
  params: ICellEditorParams | any;  // Added by David Lee 25Feb2019
  // valueSetSubscription: Subscription;  // Added by David Lee 25Feb2019
  // validateForm: FormGroup;  // Added by David Lee 1Mar2019
  message: string;
  dwType = (): string => 'default'; // 小数位数类别
  dwCode = (): string => undefined; // 小数位代码
  dwMin = (): number => undefined;          // 最小值
  dwMax = (): number => undefined;          // 最大值
  dwPositiveNum = (): number => undefined;   // 正数的小数点位数
  dwNegtiveNum = (): number => undefined;   // 负数的小数点位数
  dwHasZero = (): boolean => false; // 是否允许输入00开头的数值
  defaultDecimal = null;
  decimal = null;
  defaultRoundType = null;
  roundType = null;
  oldDwValue: number = null; // 记录旧值
  dwValue: any;
  // public value;
  dwValueDisplay: any; // 显示的数值，1.解决返回number类型小数末尾是0会舍去的问题
  @ViewChild('cellInput') cellInput: ElementRef;

  @ViewChild('numberFormatRenderer') nfRenderer: CfcNumberFormatRendererComponent;

  constructor(
    // private fb: FormBuilder,
    // @Optional() private eventHandler: FxAgGridEventHandlerService,
    private fxUserService: FxUserInfoService) {
    super();
  }

  onKeyDown(event: any): void {
    if (event.keyCode === 27) {
      event.stopPropagation();
    }
  }

  // gets called once after the editor is created
  agInit(params: ICellEditorParams | any): void {
    this.columnName = params.column.colDef.headerName;
    this.key = params.column.colId;
    this.rowId = params.node.id;
    const index = params.context.serviceObj.indexMap.get(this.rowId);
    this.rowFormGroup = ((params.context.myFormArray as FormArray).at(index) as FormGroup);

    this.getParams(params);
    this.getData();

    this.params = params;  // 传入参数
    this.colId = params.column.colId;
    this.editType = params.api.gridOptionsWrapper.gridOptions.editType || '';
    // this.formControl = new FormControl(params.value, params.validators);
    // this.formControl = _.cloneDeep(this.rowFormGroup.get(this.key));
    this.formControl = cloneAbstractControl(this.rowFormGroup.get(this.key)) as FormControl;
    // this.oldDwValue = params.value; // 记录旧值
    this.oldDwValue = this.formControl.value;
    // this.dwValue = (this.oldDwValue !== NaN || this.oldDwValue !== null || this.oldDwValue !== undefined) ? JSON.parse(JSON.stringify(this.oldDwValue)) : '';
    // 如果是直接按键编辑栏位，从 params.cellStartedEdit 获取初值
    this.dwValue = this.getFirstValue(params);
    this.dwValueDisplay = this.dwValue;
    if (params.charPress !== null) {
      setTimeout(() => {
        this.cellInput.nativeElement.focus();
        // this.cellInput.nativeElement.select();
      }, 15);
    } else {
      this.initDisplayValue();
      setTimeout(() => {
        this.cellInput.nativeElement.focus();
        this.cellInput.nativeElement.select();
      }, 15);
    }


    this.rowFormGroup.get(this.key).valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (this.oldDwValue !== value) {
        this.formControl.setValue(value);
        this.oldDwValue = this.formControl.value;
        this.dwValue = (this.oldDwValue !== NaN || this.oldDwValue !== null || this.oldDwValue !== undefined) ? JSON.parse(JSON.stringify(this.oldDwValue)) : '';
        this.dwValueDisplay = this.dwValue;
      }
    });

    super.init();
  }

  onBlur(): void {
    try {
      // 原 number 控件提供了 onChange 和 onBlur 两种回调方式
      // 但在现在使用场景里，只有失去焦点才有可能触发 onChange
      // 所以，这里合并并了 onChange 和 onBlur 方法
      // 只有修改过，才调用 onBlur 回调方法 (onBlur 当 onchange 使用)
      this.initDisplayValue();
      if (this.dwValue === this.rowFormGroup.get(this.key).value) {
        return;
      }
      this.rowFormGroup.get(this.key).patchValue(this.dwValue);
      if (this.params && this.params.onChange) {
        (this.params as any).onChange({
          gridId: '',
          value: this.dwValue,
          rowIndex: '',
          id: this.rowId,
          colId: '',
          controlName: ''
        });
      }
      if (this.params && this.params.onBlur) {
        (this.params as any).onBlur({
          gridId: '',
          value: this.dwValue,
          rowIndex: '',
          id: this.rowId,
          colId: '',
          controlName: ''
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  // Gets called once after GUI is attached to DOM.
  // Useful if you want to focus or highlight a component
  // (this is not possible when the element is not attached)
  afterGuiAttached(): void {
    // setTimeout(() => {
    //   if (this.cellInput && !this.isFullRowEdit()) {
    //     this.cellInput.nativeElement.focus();
    //     this.cellInput.nativeElement.select();
    //   }
    // }, 15);
    // 通过订阅消息回传onFocus事件
    this.cellInput.nativeElement.onfocus = (e: any): void => {
      if (this.params && this.params.onFocus) {
        this.params.onFocus({
          gridId: '',
          value: this.dwValue,
          rowIndex: '',
          id: this.rowId,
          colId: '',
          controlName: ''
        });
      }
    };

    // // 通过订阅消息回传onChange事件
    // this.cellInput.nativeElement.onchange = (event): void => {
    //   this.initDisplayValue();
    //   this.rowFormGroup.get(this.key).patchValue(this.dwValue);
    //   if (this.params && this.params.onChange) {
    //     this.params.onChange({
    //       gridId: '',
    //       value: this.dwValue,
    //       rowIndex: '',
    //       id: this.rowId,
    //       colId: '',
    //       controlName: ''
    //     });
    //   }
    // };
    //
    // // 通过订阅消息回传onBlur事件
    this.cellInput.nativeElement.onblur = (e: any): void => {
      this.onBlur();
    };

    // this.updateRendererValue();
    // this.rowFormGroup.get(this.key).valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
    //   this.updateRendererValue();
    // });
  }

  updateRendererValue(): any {
    this.nfRenderer.agInit(Object.assign(this.params, {
      data: this.rowFormGroup.getRawValue(),
      getValue: (): any => (this.rowFormGroup.get(this.key).value)
    }));
    this.nfRenderer.status = this.rowFormGroup.get(this.key).status.toLowerCase();
  }

  getParams(params: any): void {
    const paramWarpper: any = (value: any): any => {
      return (typeof value === 'function') ? value : (rowFormGroup: any): any => value;
    };
    // 下面的 param 应该支持传入方法，这样可以动态修改
    if (params.dwType) {
      this.dwType = paramWarpper(params.dwType);
    }
    if (params.dwCode) {
      this.dwCode = paramWarpper(params.dwCode);
    }
    if (params.dwMin !== undefined && params.dwMin !== null) {
      this.dwMin = paramWarpper(params.dwMin);
    }
    if (params.dwMax !== undefined && params.dwMax !== null) {
      this.dwMax = paramWarpper(params.dwMax);
    }
    if (params.dwPositiveNum !== undefined && params.dwPositiveNum !== null && params.dwPositiveNum >= 0) {
      this.dwPositiveNum = paramWarpper(params.dwPositiveNum);
    }
    if (params.dwNegtiveNum !== undefined && params.dwNegtiveNum !== null && params.dwNegtiveNum >= 0) {
      this.dwNegtiveNum = paramWarpper(params.dwNegtiveNum);
    }
    if (params.dwHasZero) {
      this.dwHasZero = paramWarpper(params.dwHasZero);
    }
  }

  // 获取设置的币种和单位
  async getData(): Promise<any> {
    if (this.dwType() === 'unit') {
      this.defaultDecimal = (this.fxUserService.getEntParamById('c_E067') === null || this.fxUserService.getEntParamById('c_E067') === undefined || this.fxUserService.getEntParamById('c_E067') === '') ? 2 : this.fxUserService.getEntParamById('c_E067');
      this.defaultRoundType = (this.fxUserService.getEntParamById('c_E068') === null || this.fxUserService.getEntParamById('c_E068') === undefined || this.fxUserService.getEntParamById('c_E068') === '') ? 2 : this.fxUserService.getEntParamById('c_E068');
    } else if ((['unitPriceDecimal', 'priceDecimal']).indexOf(this.dwType()) !== -1) {
      const defaultCode = this.fxUserService.userInfo['c_curr'];
      const defaultItems = this.fxUserService.getCurrencyParam([]);
      const defaultItem = defaultItems ? defaultItems.filter(x => x.baacurr === defaultCode)[0] : null;
      if (defaultItem) {
        if (this.dwType() === 'unitPriceDecimal') {
          this.defaultDecimal = defaultItem.baa001;
        } else {
          this.defaultDecimal = defaultItem.baa002;
        }
        this.defaultRoundType = defaultItem ? (defaultItem.baa003 ? defaultItem.baa003 : 2) : 2;
      } else {
        this.defaultDecimal = 2;
        this.defaultRoundType = 2;
      }
    }

    let items = [];
    let defaultCurr;
    if (this.dwType() === 'unit') {
      if (this.dwCode() === 'default' || !this.dwCode()) { // dwCode传的default或空，取集团参数里设置的单位默认小数位数和舍入方式
        this.decimal = (this.fxUserService.getEntParamById('c_E067') === null || this.fxUserService.getEntParamById('c_E067') === undefined || this.fxUserService.getEntParamById('c_E067') === '') ? this.defaultDecimal : this.fxUserService.getEntParamById('c_E067');
        this.roundType = (this.fxUserService.getEntParamById('c_E068') === null || this.fxUserService.getEntParamById('c_E068') === undefined || this.fxUserService.getEntParamById('c_E068') === '') ? this.defaultRoundType : this.fxUserService.getEntParamById('c_E068');
      } else {
        items = this.fxUserService.getUnitParam([]);
        defaultCurr = items ? items.filter(x => x.bacunit === this.dwCode())[0] : null;
        if (!defaultCurr) {
          this.decimal = this.defaultDecimal;
          this.roundType = this.defaultRoundType;
        } else {
          this.decimal = defaultCurr.bac002;
          this.roundType = defaultCurr.bac003 ? defaultCurr.bac003 : this.defaultRoundType;
        }
      }
    } else if ((['unitPriceDecimal', 'priceDecimal']).indexOf(this.dwType()) !== -1) {
      if (this.dwCode() === 'default' || !this.dwCode()) {// dwCode传的default或空，取缓存里的当前登录组织设置的主币别
        const result = this.fxUserService.userInfo['c_curr'];
        this.dwCode = (): string => result;
      }
      items = this.fxUserService.getCurrencyParam([]);
      defaultCurr = items ? items.filter(x => x.baacurr === this.dwCode())[0] : null;
      if (!defaultCurr) {
        this.decimal = this.defaultDecimal;
        this.roundType = this.defaultRoundType;
      } else {
        this.roundType = defaultCurr.baa003 ? defaultCurr.baa003 : this.defaultRoundType;
        switch (this.dwType()) {
          case 'unitPriceDecimal':
            this.decimal = defaultCurr.baa001;
            break;

          case 'priceDecimal':
            this.decimal = defaultCurr.baa002;
            break;

          default:
            break;
        }
      }
    }
    if (this.decimal === null) {
      this.decimal = this.defaultDecimal;
    }
    if (this.roundType === null) {
      this.roundType = this.defaultRoundType;
    }
  }

  initDisplayValue(): string {
    if (this.dwValueDisplay === undefined || this.dwValueDisplay === null) {
      return;
    }
    if (this.dwValueDisplay === '') {
      this.dwValue = '';
      return this.dwValue;
    }
    this.getData(); // 修正dwcode动态变化decimal和roundType没有重新取值导致的bug
    const reg0 = /^(\-|\+)?\d+(\.\d+)?$/;
    // if (!reg0.test(this.dwValueDisplay)) {
    if (parseFloat(this.dwValueDisplay).toString() === 'NaN') {
      this.dwValue = this.oldDwValue;
      // this.formControl.setValue(this.dwValue);
      this.dwValueDisplay = this.dwValue;
      return this.dwValue;
    } else {
      if (this.dwValueDisplay !== undefined && this.dwValueDisplay !== null && this.dwValueDisplay !== '') {
        this.dwValueDisplay = parseFloat(this.dwValueDisplay).toString();
      }
      this.dwValue = this.dwValueDisplay;
    }
    if (this.dwValue === 0) {
      this.dwValue = 0;
      // this.formControl.setValue(this.dwValue);
      this.dwValueDisplay = this.dwValue;
      return this.dwValue;
    }
    const reg01 = /^\d+(\.\d+)?$/;
    if (reg01.test(this.dwValue.toString())) { // 正数
      if (this.dwPositiveNum() !== undefined && this.dwPositiveNum() !== null && this.dwPositiveNum() >= 0) { // 设置了正数小数位数
        this.roundType = 2;        // 使用作业自定义小数位数默认采用舍入类型为四舍六入五成双
        this.defaultRoundType = 2;
        this.decimal = this.dwPositiveNum();  // 使用作业自定义小数位数
        this.defaultDecimal = this.dwPositiveNum();
      }
    } else {
      if (this.dwNegtiveNum() !== undefined && this.dwNegtiveNum() !== null && this.dwNegtiveNum() >= 0) { // 设置了负数小数位数
        this.roundType = 2;        // 使用作业自定义小数位数默认采用舍入类型为四舍六入五成双
        this.defaultRoundType = 2;
        this.decimal = this.dwNegtiveNum();  // 使用作业自定义小数位数
        this.defaultDecimal = this.dwNegtiveNum();
      }
    }
    if (this.dwHasZero()) { // 允许输入0开头的数值
      this.dwValue = this.dwValueDisplay.replace(/\D/g, '');
      this.oldDwValue = this.dwValue;
    } else {
      if (this.decimal !== undefined && this.decimal !== null && this.decimal >= 0) {
        this.dwValue = this.roundFormat(this.decimal, Number(this.dwValue), this.roundType); // 舍入和自动补0
        if ((this.dwMin() !== undefined && Number(this.dwValue) < this.dwMin()) || (this.dwMax() !== undefined && Number(this.dwValue) > this.dwMax())) {
          this.dwValue = this.oldDwValue;
        } else {
          this.oldDwValue = this.dwValue;
        }
      } else {
        this.dwValue = this.oldDwValue;
      }
    }
    this.dwValueDisplay = this.dwValue;
    // this.formControl.setValue(this.dwValue);
    return this.dwValue;
  }

  /**
   * Decimal adjustment of a number.
   *
   * @param  {String}  type  The type of adjustment.
   * @param  {Number}  value  The number.
   * @param  {Integer}  exp    The exponent (the 10 logarithm of the adjustment base).
   * @returns  {Number}      The adjusted value.
   */
  decimalAdjust(type: string, value: any, exp: Number): number {
    // If the exp is undefined or zero...
    if (typeof exp === 'undefined' || +exp === 0) {
      return Math[type](value);
    }
    value = +value;
    exp = +exp;
    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
      return NaN;
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Shift back
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
  }

  // Decimal round
  round10 = function (value: any, exp: Number) {
    return this.decimalAdjust('round', value, exp);
  };
  // Decimal floor
  floor10 = function (value: any, exp: Number) {
    return this.decimalAdjust('floor', value, exp);
  };
  // Decimal ceil
  ceil10 = function (value: any, exp: Number) {
    return this.decimalAdjust('ceil', value, exp);
  };

  roundFormat(decimal: any, num: any, roundType: any): number {
    if (decimal !== null) {
      if (roundType === '1') {  // 舍入类型为四舍五入
        num = this.round10(num, -1 * decimal);
      } else if (roundType === '2') {  // 舍入类型为四舍六入五成双
        num = num.toFixed(decimal);
      } else if (roundType === '3') {  // 舍入类型为无条件舍弃
        num = this.floor10(num, -1 * decimal);
      } else if (roundType === '4') {  // 舍入类型为无条件进位
        num = this.ceil10(num, -1 * decimal);
      }
      // 修改整数的时候不会显示0的问题
      if (num.toString().indexOf('.') === -1 && decimal !== 0) {
        num += '.';
        for (let i = 0; i < decimal; i++) {
          num += '0';
        }
      }

      // 数值小数点后缺位补0
      if (num && num.toString().indexOf('.') !== -1 && decimal !== 0) {
        const numLength = Number((num.toString().split('.')[1]).length);
        if (numLength < decimal) {
          for (let i = numLength; i < decimal; i++) {
            num += '0';
          }
        }
      }
    }
    return num;
  }

  // If doing full row edit, then gets called when tabbing into the cell.
  focusIn(): void {
    this.cellInput.nativeElement.focus();
  }

  // If doing full row edit, then gets called when tabbing out of the cell.
  focusOut(): void {
    this.cellInput.nativeElement.blur();
  }

  // Should return the final value to the grid, the result of the editing
  getValue(): any {
    return this.dwValueDisplay;
    // return this.initValue();
  }

  // Gets called once before editing starts, to give editor a chance to
  // cancel the editing before it even starts.
  isCancelBeforeStart(): boolean {
    return false;
  }

  // Gets called once when editing is finished (eg if enter is pressed).
  // If you return true, then the result of the edit will be ignored.
  isCancelAfterEnd(): boolean {
    this.onBlur();
    return this.validationMessages.length > 0;
  }

  // Gets called once after initialised.
  // If you return true, the editor will appear in a popup
  isPopup(): boolean {
    return false;
  }

  ngOnDestroy(): void {
  }


  private getFirstValue(params: ICellEditorParams | any): any {
    let startValue;
    // cellStartedEdit is only false if we are doing fullRow editing
    if (params.cellStartedEdit) {
      const keyPressBackspaceOrDelete = params.keyPress === 8
        || params.keyPress === 46;
      if (keyPressBackspaceOrDelete) {
        startValue = '';
      } else if (params.charPress !== null) {
        startValue = params.charPress;
      } else {
        startValue = params.value;
      }
    }
    if (startValue !== null && startValue !== undefined && startValue !== '') {
        // this.rowFormGroup.get(params.column.colId).setValue(startValue);
        return startValue;
    } else {
      return (this.oldDwValue !== NaN || this.oldDwValue !== null || this.oldDwValue !== undefined) ? JSON.parse(JSON.stringify(this.oldDwValue)) : '';
    }
  }
}

