import { Injectable } from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Subject, Observable} from 'rxjs';
import * as _ from 'lodash';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
import { FxBaseModel } from '../fxbase/fx-base.model';
import { cloneAbstractControl } from 'app/implementation/shared/services/common-method';

@Injectable()
export class FxGridRowEditService extends FxBaseModel {

  constructor(public http: FxDapHttpClient) {
    super(http);
 }

  // 作业 component
  public componentParent: any;

  // 记录 detail grid 的 row id 和初始 row index 的印射关系
  public indexMap: Map<string, number> = new Map();
  // 记录 detail grid 的验证失败的 id 和 controls
  public invalidFormControls: Map<string, any> = new Map();

  // 根据 formArray.value 重新计算 indexMap
  public initIndex(dataSet: any): void {
    this.indexMap.clear();
    dataSet.forEach((item, index) => {
      this.indexMap.set(item.id, index);
    });
  }
  // id 最大值
  public maxId: number = 0;
  // 下一个 id
  public getNextId(): number {
    return ++this.maxId;
  }

  // 清理临时数据，每次对 grid 作全部更新时调用
  public cleanTempData(): void {
    // 查询； 单头增删改，切换；
    this.indexMap = new Map();
    this.invalidFormControls = new Map();
    this.maxId = 0;
    // this.rowCachedFormGroup.valueChanges
    this.rowCachedFormGroup = null;
  }

  // 行编辑下，对应正在编辑行的 formGroup
  public rowCachedFormGroup: FormGroup;
  //单元格编辑下 对应得正在编辑单元格的表单
  public cellCachedFormGroup: FormGroup; 
  // 创建 rowCachedFormGroup
  public initRowCachedFormGroup(originFormGroup: FormGroup, destroy$?: Subject<any>): void {
    // 行编辑时，只有确认后，才能将 rowCachedFromGroup 写入 formArray 。因此这里需用深拷贝
    const _rowCachedFormGroup = cloneAbstractControl(originFormGroup);
    this.rowCachedFormGroup = _rowCachedFormGroup;
  }

  public initCellCachedFormGroup(originFormGroup: FormGroup, destroy$?: Subject<any>): void {
    // 行编辑时，只有确认后，才能将 rowCachedFromGroup 写入 formArray 。因此这里需用深拷贝
    const _cellCachedFormGroup = cloneAbstractControl(originFormGroup);
    this.cellCachedFormGroup = _cellCachedFormGroup;
  }

  public getDataWithEditingRow(formArrayData: Array<any>): Array<any> {
    if (!this.rowCachedFormGroup) {
      return formArrayData;
    }
    const currentRowData = this.rowCachedFormGroup.getRawValue();
    const currentIndex = this.indexMap.get(currentRowData.id);
    return formArrayData.map((row, index) => {
      return (index === currentIndex) ? currentRowData : row;
    });
  }

  //模拟rowNode的模型
  public getAllRowNode (formArrayData: Array<any>) {
    return this.getDataWithEditingRow(formArrayData).map((data)=>({data:data, id: data.id}));
  }

  // getDisplayField
  public getDisplayField( programNo: any): Observable<any> {
    return this.http.post('A/IACommonService/getDisplayField', {param: {programNo: programNo}});
  }

}
