import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { FxDapHttpClient } from '../../fx-http-client';
import { DataType, Data } from './ngx-address';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn, AbstractControl } from '@angular/forms';
import { FxUserInfoService } from 'app/implementation/auth/fx-auth/fx-user-info.service';
import { DwRoutingMessageService } from '@webdpt/framework';
import { TranslateService } from '@ngx-translate/core';

// TODO
// 1. 套用参数
// 2. required 验证
// 3. 可选的地址验证

@Component({
  selector: 'fx-address-picker',
  templateUrl: './fx-address-picker.component.html',
  styleUrls: ['./fx-address-picker.component.less'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FxAddressPickerComponent),
    multi: true
  }]
})
export class FxAddressPickerComponent implements OnInit, ControlValueAccessor {
  @Input() programId: string;

  private model: any;
  // 地址录入区域 c_E025
  public default_start_index = '1';
  // 地址隐藏区域 c_E026
  public default_end_index = '5';
  // 地址必输区域 c_E027
  public default_required_index = '1';

  // 默认国家
  public default_country = 'CN';

  addressSource = {
    country: {
      postUrl: 'A/IACommonService/getCountry',
      param: (): any => ({ param: {} }),
      transformer: (county: any, index: number): any => ({
        index: index,
        id: county.country_no,
        name: county.country_name,
        other_data: true
      })
    },
    province: {
      postUrl: 'A/IACommonService/qryProvince',
      param: (country): any => ({ param: { country_no: country } }),
      transformer: (province: any, index: number): any => ({
        index: index,
        id: province.province_no,
        name: province.province_name,
        other_data: true
      })
    },
    city: {
      postUrl: 'A/IACommonService/getCity',
      param: (province): any => ({ param: { province_no: province } }),
      transformer: (city: any, index: number): any => ({
        index: index,
        id: city.city_no,
        name: city.city_name,
        other_data: true
      })
    },
    district: {
      postUrl: 'A/IACommonService/getDistrict',
      param: (city): any => ({ param: { city_no: city } }),
      transformer: (district: any, index: number): any => ({
        index: index,
        id: district.district_no,
        name: district.district_name,
        other_data: true
      })
    },
    street: {
      postUrl: 'A/IACommonService/getStreet',
      param: (district): any => ({ param: { district_no: district } }),
      transformer: (street: any, index: number): any => ({
        index: index,
        id: street.street_no,
        name: street.street_name,
        other_data: true
      })
    },
    community: {
      postUrl: 'A/IACommonService/qryResidentialQuar',
      param: (params: {
        country: string,
        province: string,
        city: string,
        district: string,
        street: string
      }): any => ({
        param: {
          country_no: params.country,
          province_no: params.province,
          city_no: params.city,
          district_no: params.district,
          street_no: params.street
        }
      }),
      transformer: (community: any, index: number): any => ({
        index: index,
        id: community.residential_no,
        name: community.residential_name,
        other_data: true
      })
    }
  };
  types = ['国家', '省', '市', '区县', '街道', '小区'];
  addressValues: Array<string> = [];
  address: { id: string; result: {}; options: { placeholder: string; types: string[]; http: (index: number, id: string) => any; required_index: string; } };

  getLabel(index: number): string {
    if (this.default_start_index !== "1") {
      switch (index) {
        case 0:
          return 'province';
        case 1:
          return 'city';
        case 2:
          return 'district';
        case 3:
          return 'street';
        case 4:
          return 'community';
        default:
          return 'province';
      }
    } else {
      switch (index) {
        case 0:
          return 'country';
        case 1:
          return 'province';
        case 2:
          return 'city';
        case 3:
          return 'district';
        case 4:
          return 'street';
        case 5:
          return 'community';
        default:
          return 'province';
      }
    }

  }

  constructor(
    private http: FxDapHttpClient,
    public userinfo: FxUserInfoService,
    public dwMessage: DwRoutingMessageService,
    private translateService: TranslateService,
  ) {
  }

  public ngOnInit(): any {
    this.default_country = this.userinfo.getEntParamById('c_E001');
    this.userinfo.getProgramParamById$(this.programId).subscribe(response => {
      this.default_start_index = response.get('c_P003') ? response.get('c_P003') : this.userinfo.getEntParamById('c_E025');
      this.default_end_index = response.get('c_P004') ? response.get('c_P004') : this.userinfo.getEntParamById('c_E026');
      this.default_required_index = response.get('c_P005') ? response.get('c_P005') : this.userinfo.getEntParamById('c_E027');
      this.default_required_index = Number(this.default_required_index) > Number(this.default_end_index) ? this.default_end_index : this.default_required_index;
      this.default_required_index = this.default_country=='CN'? '1': this.default_required_index;
      
      if (this.default_end_index == "6") {
        this.types = ['国家', '省', '市', '区县', '街道', '小区'];
      } else if (this.default_end_index == "5") {
        this.types = ['国家', '省', '市', '区县', '街道'];
      } else if (this.default_end_index == "4") {
        this.types = ['国家', '省', '市', '区县'];
      } else if (this.default_end_index == "3") {
        this.types = ['国家', '省', '市'];
      }
      if (this.default_start_index !== "1") {
        var t = [];
        for (var m = 1; m < this.types.length; m++) {
          t.push(this.types[m]);
        }
        this.types = t;
      }
      this.address = {
        id: '',
        result: {},
        options: {
          placeholder: '请选择国家，省市',
          types: this.types,
          http: (index: number, id: string): any => {
            return Observable.create(observer => {

              const label = this.getLabel(index);

              const source = this.addressSource[label];

              let upper: any = this.default_country;
              // 刚初始化完成时，result 是空的
              // 此时需要从 values(预设值) 中取数据，
              // 在地址控件上点选操作后，会更新 result ，之后应从 result 中取值。
              if (this.address.result['id'] || (this.address.result['paths'] && this.address.result['paths'].length > 0)) {
                upper = (label !== 'community')
                  // ? (this.address.result['id'] ? this.address.result['id'] :(label === 'province' ? default_country : this.getParam(label)))
                  ? (this.address.result['id'] ? this.address.result['id'] : this.getParam(label))
                  : this.default_start_index !== "1"
                    ?
                    {
                      province: this.address.result['paths'][0]['id'] || '',
                      city: this.address.result['paths'][1]['id'] || '',
                      district: this.address.result['paths'][2]['id'] || '',
                      street: this.address.result['paths'][3]['id'] || ''
                    }
                    : {
                      country: this.address.result['paths'][0]['id'] || '',
                      province: this.address.result['paths'][1]['id'] || '',
                      city: this.address.result['paths'][2]['id'] || '',
                      district: this.address.result['paths'][3]['id'] || '',
                      street: this.address.result['paths'][4]['id'] || ''
                    }

              } else {
                upper = index > 0
                  ? (this.addressValues[index - 1] || id)
                  : upper;
                if (label === 'community') {
                  if (this.default_start_index !== "1") {
                    upper = {
                      province: this.addressValues[0] || '',
                      city: this.addressValues[1] || '',
                      district: this.addressValues[2] || '',
                      street: this.addressValues[3] || ''
                    };
                  } else {
                    upper = {
                      country: this.addressValues[0] || '',
                      province: this.addressValues[1] || '',
                      city: this.addressValues[2] || '',
                      district: this.addressValues[3] || '',
                      street: this.addressValues[4] || ''
                    };
                  }
                }
              }

              const param = source.param(upper);
              this.http.post(source.postUrl, param).subscribe(res => {
                const data = (res.success && res.data)
                  ? res.data.map(item => (source.transformer(item)))
                  : [];
                const _tmp: Data = {
                  type: DataType.list,
                  list: data
                };
                observer.next(_tmp);
                observer.complete();
              }, error => {
                observer.next({
                  type: DataType.list,
                  list: []
                });
                observer.complete();
              });
            });
          },
          required_index: this.default_required_index
        }
      };
    });
  }

  // 获取参数
  getParam(label: any): any {
    switch (label) {
      case 'country':
        return "";

      case 'province':
        return this.addressValues[0];

      case 'city':
        return this.addressValues[1];

      case 'district':
        return this.addressValues[2];

      case 'street':
        return this.addressValues[3];

      default:
        return '';
    }
  };

  writeValue(obj: any): void {
    if (!obj) {
      return;
    }
    this.model = obj;
    let temp = ['', '', '', '', '', ''];
    if (this.default_start_index !== "1") {
      temp[0] = this.model.province || '';
      temp[1] = this.model.city || '';
      temp[2] = this.model.district || '';
      temp[3] = this.model.street || '';
      temp[4] = this.model.community || '';
    } else {
      temp[0] = this.model.country || '';
      temp[1] = this.model.province || '';
      temp[2] = this.model.city || '';
      temp[3] = this.model.district || '';
      temp[4] = this.model.street || '';
      temp[5] = this.model.community || '';
    }
    this.addressValues = temp;
  }

  public onModelChange: Function = (model) => {
    console.log('onModelChange', model);
  }

  public onModelTouched: Function = (model) => {
    console.log('onModelTouched', model);
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  is_init = true;
  onSelected(value: any): void {
    if (this.is_init) {this.is_init = false; return;}
    console.log('chose address', value);
    this.address.result = value;
    if (this.default_start_index !== "1") {
      this.model = {
        msg: value.msg,
        country: this.default_country,
        province: this.address.result['paths'][0] ? this.address.result['paths'][0]['id'] : '',
        city: this.address.result['paths'][1] ? this.address.result['paths'][1]['id'] : '',
        district: this.address.result['paths'][2] ? this.address.result['paths'][2]['id'] : '',
        street: this.address.result['paths'][3] ? this.address.result['paths'][3]['id'] : '',
        community: this.address.result['paths'][4] ? this.address.result['paths'][4]['id'] : '',
        country_name: "中国",
        province_name: this.address.result['paths'][0] ? this.address.result['paths'][0]['name'] : '',
        city_name: this.address.result['paths'][1] ? this.address.result['paths'][1]['name'] : '',
        district_name: this.address.result['paths'][2] ? this.address.result['paths'][2]['name'] : '',
        street_name: this.address.result['paths'][3] ? this.address.result['paths'][3]['name'] : '',
        community_name: this.address.result['paths'][4] ? this.address.result['paths'][4]['name'] : '',
      };
    } else {
      this.model = {
        msg: value.msg,
        country: this.address.result['paths'][0] ? this.address.result['paths'][0]['id'] : '',
        province: this.address.result['paths'][1] ? this.address.result['paths'][1]['id'] : '',
        city: this.address.result['paths'][2] ? this.address.result['paths'][2]['id'] : '',
        district: this.address.result['paths'][3] ? this.address.result['paths'][3]['id'] : '',
        street: this.address.result['paths'][4] ? this.address.result['paths'][4]['id'] : '',
        community: this.address.result['paths'][5] ? this.address.result['paths'][5]['id'] : '',
        country_name: this.address.result['paths'][0] ? this.address.result['paths'][0]['name'] : '',
        province_name: this.address.result['paths'][1] ? this.address.result['paths'][1]['name'] : '',
        city_name: this.address.result['paths'][2] ? this.address.result['paths'][2]['name'] : '',
        district_name: this.address.result['paths'][3] ? this.address.result['paths'][3]['name'] : '',
        street_name: this.address.result['paths'][4] ? this.address.result['paths'][4]['name'] : '',
        community_name: this.address.result['paths'][5] ? this.address.result['paths'][5]['name'] : '',
      };
    }
    // if (this.needindex == "1" && this.model.country == "") {
    //   this.dwMessage.errorNew(this.translateService.instant("address_country"), this.translateService.instant("type_address_country"), "", "");
    //   return;
    // } else if (this.needindex == "2" && this.model.province == "") {
    //   this.dwMessage.errorNew(this.translateService.instant("address_province"), this.translateService.instant("type_address_province"), "", "");
    //   return;
    // } else if (this.needindex == "3" && this.model.city == "") {
    //   this.dwMessage.errorNew(this.translateService.instant("address_city"), this.translateService.instant("type_address_city"), "", "");
    //   return;
    // } else if (this.needindex == "4" && this.model.district == "") {
    //   this.dwMessage.errorNew(this.translateService.instant("address_district"), this.translateService.instant("type_address_district"), "", "");
    //   return;
    // } else if (this.needindex == "5" && this.model.street == "") {
    //   this.dwMessage.errorNew(this.translateService.instant("address_street"), this.translateService.instant("type_address_street"), "", "");
    //   return;
    // } else if (this.needindex == "6" && this.model.community == "") {
    //   this.dwMessage.errorNew(this.translateService.instant("address_community"), this.translateService.instant("type_address_community"), "", "");
    //   return;
    // }
    this.onModelChange(this.model);
  }
}
