import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FxButtonEditComponent } from './fx-button-edit.component';
import {DwDocumentModule, FrameworkUIModule} from '@webdpt/framework';
import {FxDapModule} from '../../fx-http-client';
import {PipesModule} from '../pipe/pipes.module';
import {MatAutocompleteModule} from '@angular/material';
import { AutocompleteModule } from '../../autocomplete/autocomplete.module';

@NgModule({
  declarations: [FxButtonEditComponent],
  imports: [
    CommonModule,
    FrameworkUIModule,
    FxDapModule,
    DwDocumentModule,
    PipesModule,
    MatAutocompleteModule,
    AutocompleteModule
  ],
  exports: [
    FxButtonEditComponent
  ],
  entryComponents: [
    FxButtonEditComponent
  ]
})
export class FxButtonEditModule { }
