// import { DwModalRef } from 'ng-quicksilver';
// import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit, ViewEncapsulation } from '@angular/core';
// import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
// import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { isString, isDate, isArray } from 'util';
import { DatePipe } from '@angular/common';
// import { DwUserService, DwRoutingMessageService } from '@webdpt/framework';
// import { DwModalService } from 'ng-quicksilver';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'more-comcond',
  template:
  `<div class="fx_modal_container">

<!-- [style.display]="!isCollapse?'block':'none'" -->
    <div dw-row class="ant-advanced-search-form dw-f-search-form-block" [style.display]="!isCollapse?'block':'none'">
      <div dw-col [dwSpan]="12" *ngFor="let searchObj of searchVal; index as index">
        <div dw-col [dwSpan]="24" [ngClass]="{'noneMargin': searchVal.length > 2 }">
          <dw-form-item dw-row>
            <dw-form-control style="width: 33%">
              <dw-select style="width: 100%" dwMode="default"  [(ngModel)]="searchObj.field_no" (ngModelChange)="changesearchfield(index)">
                <dw-option *ngFor="let option of searchlableList" [dwLabel]="option.label" [dwValue]="option.value"></dw-option>
              </dw-select> 
            </dw-form-control>
            <dw-form-control style="width: 26%">
              <dw-select style="width: 100%" dwMode="default"  [(ngModel)]="searchObj.condition" >
                <dw-option *ngFor="let option of searchCondList[searchObj.cond_type]" [dwLabel]="option.label" [dwValue]="option.value"></dw-option>
              </dw-select>
            </dw-form-control>
            <dw-form-control style="width: 45%" *ngIf="searchObj.lable_type == '1'">
              <dw-form-input name="searchvalue2" [(ngModel)]="searchObj.value" dwInputSpan="24"></dw-form-input>
            </dw-form-control>
            <dw-form-control style="width: 45%" *ngIf="searchObj.lable_type == '2'">
              <dw-form-input name="searchvalue2" [(ngModel)]="searchObj.value" dwInputSpan="24"></dw-form-input>
            </dw-form-control>
            <dw-form-control style="width: 45%" *ngIf="searchObj.lable_type == '3'">
              <dw-form-input name="searchvalue2" [(ngModel)]="searchObj.value" dwInputSpan="24"></dw-form-input>
            </dw-form-control>
            <dw-form-control style="width: 45%" *ngIf="searchObj.lable_type == '4'">
              <dw-form-date-picker name="searchvalue2"  [(ngModel)]="searchObj.value" dwInputSpan="24">
              </dw-form-date-picker>
            </dw-form-control>
            <dw-form-control style="width: 45%" *ngIf="searchObj.lable_type == '5'">
              <dw-form-date-picker name="searchvalue2"  [(ngModel)]="searchObj.value" dwShowTime dwInputSpan="24">
              </dw-form-date-picker>
            </dw-form-control>
            <dw-form-control style="width: 45%" *ngIf="searchObj.lable_type == '6'">
              <dw-form-input name="searchvalue2" [(ngModel)]="searchObj.value" dwInputSpan="24"></dw-form-input>
            </dw-form-control>
            <dw-form-control style="width: 45%" *ngIf="searchObj.lable_type == '7'">
              <dw-month-picker  [(ngModel)]="searchObj.value">
              </dw-month-picker>
            </dw-form-control>
            <dw-form-control style="width: 45%" *ngIf="searchObj.lable_type == '8'">
              <dw-form-range-picker [(ngModel)]="searchObj.value"
                [dwPlaceHolder]="['开始日期','结束日期']">
              </dw-form-range-picker>
            </dw-form-control>
          </dw-form-item>
        </div>
      </div>
    </div>
      </div>
  `,
  styles: [
    `
    .fx_search_more {
      position: absolute;
      bottom: -25px;
      // display: flex;
      width: 100%;
      justify-content: flex-end;
    }
    .fx_search_more a {
      display: inline-block;
      width: 24px;
      height: 24px;
      border-radius: 50px;
      margin: 0 5px;
      background: #1890ff;
      text-align: center;
      line-height: 24px;
      color: #fff;
      box-shadow: 0px 1px 2px #1890ff;
      transition: .5s all;
      -webkit-transition: .5s all;
    }
    .fx_search_more a i {
      font-size: 15px;
    }
    //失效状态
    .fx_search_more a.none {
      color: #3fa3ff;
      background: #69c0ff;
      box-shadow: 0px 1px 2px #bae7ff;
      cursor: not-allowed;
    }
    //减少背景颜色
    .fx_search_more a[ng-reflect-set-title^="减少查询"] {
      background: #69c0ff;
      box-shadow: 0px 1px 2px #bae7ff;
    }
    .fx_search_more a:hover {
      transform: scale(1.1);
      -webkit-transform: scale(1.1);
    }
    :host ::ng-deep .customize-footer {
      border-top: 1px solid #e9e9e9;
      padding: 10px 18px 0 10px;
      text-align: right;
      border-radius: 0 0 0px 0px;
      margin: 15px -16px -5px -16px;
    }
    .ant-advanced-search-form {
      padding: 10px 15px 10px;
      background: #fff;
      margin: 0 !important;
      z-index: 999;
    }
    .search-form-row {
      padding-bottom: 15px;
    }
    .search-form-row-label {
      text-align: right;
      vertical-align: middle;
      line-height: 32px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .search-form-row-label label:after {
        content: ":";
        margin: 0 8px 0 2px;
        position: relative;
        top: -0.5px;
    }
    .number-align {
      text-align: right;
    }
    //查询按钮
      .dw-f-btn .ant-form-item, .ant-modal-content .ant-form-item
      {
          margin-bottom: 0px;
      }
      .dw-f-btn button
      {
        margin-left: 5px;
      }
     //更多条件
      .ant-advanced-search-form[_ngcontent-c5] {
        padding-top: 6px;
      }
      a.none {
        color: rgba(0, 0, 0, 0.25);
      }
      //by yaohuan
      .ng-star-inserted {
        line-height: 15px;
      }
      //带icon 按钮
    .dw-f-btn button.ant-btn {
        padding: 0 7px;
        // height: 30px !important;
        // line-height: 30px;
      i {
          font-size: 15px;
        }
    }
    .dw-f-btn > div.ant-col-5 {
      padding-left: 0 !important;
    }
    .fx_control_leftMar {
      margin-left: 5px;
    }
    .fx_f_btn > div {
      float: left;
    }
    .fx_f_btn div:nth-child(2) {
      padding: 0 !important;
    }
    `
  ],
  encapsulation: ViewEncapsulation.None,
})
export class moreComCondComponent implements OnInit {
  handleCancel(): void {
    console.log('Button cancel clicked!');
    // this.modalSubject.triggerCancel();
  }
  // @Input() dwComponentParams: any;
  // public _programId: string = '';
  // public aan004: string;
  // public aan001: string = '';
  // public _programId: string = this.dwComponentParams.aan002;
  // @Input() set programId(programId: string) {
  //   this._programId = (programId && programId.trim());
  // }
  // get programId(): string {
  //   return this._programId;
  // }

  // tslint:disable-next-line: no-output-on-prefix
  @Input() searchData: any;
  @Output() onSearch = new EventEmitter<any>();
  // @Output() openModal = new EventEmitter<boolean>();
  // // tslint:disable-next-line: no-output-on-prefix
  // @Output() onReady = new EventEmitter<any>();
  // @Output() OptionList = new EventEmitter<boolean>();
  public nowOpenIndex = 0;
  // 進階查詢開合 true:關閉, false:打開
  public isCollapse = false;
  // 查询条件  ==  begin ==
  public searchParam = {
    pageable: true,
    // pageSize: 10,
    // pageNumber: 1,
    glikesel: '',
    gcomConds: '',
    orderfields: [],
    condition: {
      joinOperator: 'AND',
      items: []
    }
  }; // 查询条件结果
  public gcomCondsList = []; // 常用查询条件
  public searchlableList = []; // 更多查询label
  // public searchCondList = []; // 更多查询条件
  public searchCondList = {
    'all': [], 'text': [], 'select': [], 'number': []
  }; // 更多查询条件 //根据字段类型不同显示不同内容
  public searchVal = []; // 更多查询value
  public searchValNum = 2; // 更多查询个数
  public searchValObj = { table_no: '', field_no: '', condition: 'EQUAL', value: '', lable_type: '3', more01: [], cond_type: 'all', aae005: 'VARCHAR2' };
  // public gcomCondsList = [
  //   { text: '常用条件1', value: '1' },
  //   { text: '常用条件2', value: '2' }
  // ];
  // 查询条件字段
  // lable_type 1开窗 2下拉 3输入 4日期 5日期+时间
  // 查询条件  ==  end  ==
  // public addMasterRowData: Fxai033S01MasterModel;
  // public MasterService: Fxai033S01MasterService;
  // public component: Fxai033S01Component;
  constructor(
    // private modalSubject: DwModalRef,
    // protected router: Router,
    // protected route: ActivatedRoute,
    // public dwMessage: DwRoutingMessageService,
    // // public http: FxDapHttpClient,
    // private userService: DwUserService,
    // public dwModalService: DwModalService,
    // // public doc: DwDocument,
    private translateService: TranslateService
    ) {
  }

  ngOnInit(): void {
    // this._programId = this.dwComponentParams.aan002;
    // this.aan001 = this.dwComponentParams.aan001;
    this.pageInit(); // 页面初始化
    // this.dataInit(); // 获取初始数据
    // this.getStorageCondition();
    // this.searchData();
  }

  /**
   * name
   */
  public pageInit(): void {
    // 1.开窗，=(in)，不等于，包含，不包含，空白，非空
    // 2.下拉，=(in)，不等于，空白，非空
    // 3.输入，字符类型：=(in)，不等于，包含，不包含，空白，非空；数值类型：=，>=，<=，>，<，不等于，区间，空白，非空
    // 4.日期，=，>=，<=，>，<，不等于，区间，空白，非空
    // 5.日期时间，=，>=，<=，>，<，不等于，区间，空白，非空
    this.searchCondList = {
      'all': [
        { label: '=', value: 'EQUAL' },
        { label: '>', value: 'GreaterThan' },
        { label: '>=', value: 'GreaterThanOrEqualTo' },
        { label: '<', value: 'LessThan' },
        { label: '<=', value: 'LessThanOrEqualTo' },
        { label: '<>', value: 'NotEquals' },
        { label: '包含', value: 'LIKE' },
        { label: '不包含', value: 'NOT_LIKE' },
        { label: '区间', value: 'BETWEEN' },
        { label: '空白', value: 'IS_BLANK' },
        { label: '非空', value: 'NOT_BLANK' },
        { label: '开头', value: 'StartWith' },
        { label: '结尾', value: 'EndWith' }
      ],
      'text': [
        { label: '=', value: 'EQUAL' },
        { label: '<>', value: 'NotEquals' },
        { label: '包含', value: 'LIKE' },
        { label: '不包含', value: 'NOT_LIKE' },
        { label: '空白', value: 'IS_BLANK' },
        { label: '非空', value: 'NOT_BLANK' },
        { label: '开头', value: 'StartWith' },
        { label: '结尾', value: 'EndWith' }
      ],
      'select': [
        { label: '=', value: 'EQUAL' },
        { label: '<>', value: 'NotEquals' },
        { label: '空白', value: 'IS_BLANK' },
        { label: '非空', value: 'NOT_BLANK' }
      ],
      'number': [
        { label: '=', value: 'EQUAL' },
        { label: '>', value: 'GreaterThan' },
        { label: '>=', value: 'GreaterThanOrEqualTo' },
        { label: '<', value: 'LessThan' },
        { label: '<=', value: 'LessThanOrEqualTo' },
        { label: '<>', value: 'NotEquals' },
        { label: '区间', value: 'BETWEEN' },
        { label: '空白', value: 'IS_BLANK' },
        { label: '非空', value: 'NOT_BLANK' }
      ],
    };

    if (this.searchData && this.searchData.length) { // 获取传参过来有几个字段需要查询
      // 初始化查询栏位-下拉
      // this.searchlableList = [];
      // this.searchData.forEach(obj => {
      //   this.searchlableList.push({label: obj.headerName, value: obj.field, lable_type: '3'});
      // });
      console.log(this.searchData);
      this.searchlableList = this.searchData;
      this.searchlableList.forEach(obj => {
        obj.value = obj.aap002;
        obj.label = obj.aap002_n1;
        // obj.lable_type = obj.aap003; // quwj test 后期调整
      })
      // this.searchlableList = JSON.parse(JSON.stringify(this.searchlableList));
      // this.searchlableList.push({ label: this.translateService.instant('com-请选择'), value: '' });
      
      // 初始化查询栏位-显示
      for (let index = 0; index < this.searchlableList.length; index++) {
        // 重新赋值
        var searchObj = JSON.parse(JSON.stringify(this.searchlableList[index]));
        searchObj.field_no = this.searchlableList[index].value;
        searchObj.value = '';
        // const newobj = Object.assign({}, searchObj);
        var newobj = Object.assign({}, this.searchValObj);
        newobj = Object.assign(newobj, searchObj);
        this.searchVal[index] = newobj;
        this.changesearchfield(index);
      }
    } else {
      for (let index = 0; index < this.searchValNum; index++) {
        const newobj = Object.assign({}, this.searchValObj);
        this.searchVal[index] = newobj;
      }
    }
    
    console.log(this.searchVal);
  }

  /**
   * name
   */
  // public dataInit(): void {
  //   this.searchlableList = []; // 更多查询label
  //   var labelParam = {
  //     aah001: this._programId,
  //     option_type: '3'
  //   };
  //   this.http.post<any>('A/IACommonService/pub_get_aah', { param: labelParam }).subscribe(result => {
  //     console.log('更多查询label', result);
  //     this.searchlableList = result.data;
  //     this.searchlableList.push({ label: this.translateService.instant('com-请选择'), value: '' });
  //     // this.onAfterSearch();

  //   });
  //   var condParam = {
  //     is_enum: 'Y',
  //     aaa001: '4'
  //   };
  //   this.http.post<any>('A/IACommonService/pub_get_aaa', { param: condParam }).subscribe(result => {
  //     console.log('更多查询label', result);
  //     this.searchCondList.all = result.data;
  //     this.searchCondList.text = this.getCondlabel(this.searchCondList.text);
  //     this.searchCondList.select = this.getCondlabel(this.searchCondList.select);
  //     this.searchCondList.number = this.getCondlabel(this.searchCondList.number);
  //     // this.onAfterSearch();
  //   });
  //   console.log('当前用户信息', this.userService.userInfo);
  //   // this.getComCondData();

  // }

  // getComCondData(): void {
  //   var comCondParam = {
  //     aan001: this.aan001, // 用户编号
  //     aan002: this._programId // 作业编号
  //   };
  //   this.http.post<any>('A/IACommonService/pub_get_aan', { param: comCondParam }).subscribe(result => {
  //     console.log('常用查询', result);
  //     this.gcomCondsList = result.data;
  //     this.gcomCondsList.push({ label: '保存常用查询', value: 'save' });
  //     this.gcomCondsList.push({ label: '编辑常用查询', value: 'edit' });
  //     var filterData = this.gcomCondsList.filter(obj => obj.value === this.dwComponentParams.aan003);
  //     if (filterData.length > 0) {
  //       this.searchParam.gcomConds = filterData[0].value;
  //       var aan004str = JSON.parse(filterData[0].aan004);
  //       if (aan004str.moresel) {
  //         this.searchVal = aan004str.moresel;
  //       }
  //       this.searchParam.glikesel = aan004str.likesel || '';
  //       this.searchData();
  //     }
  //   });
  // }

  // getStorageCondition(): void {
  //   var searchSession = sessionStorage.getItem(this.programId);
  //   if (searchSession !== null && searchSession !== undefined && searchSession !== 'null' && searchSession !== '') {
  //     this.searchVal = JSON.parse(searchSession).searchVal;
  //     this.searchParam.gcomConds = JSON.parse(searchSession).gcomConds;
  //     this.searchParam.glikesel = JSON.parse(searchSession).glikesel;
  //   } else {
  //     this.searchParam.gcomConds = '';
  //     this.searchParam.glikesel = '';
  //   }
  // }

  /**
   * getCondlabel
   * 获取更多查询条件
   */
  // public getCondlabel(xarry: any) {
  //   xarry.forEach(element => {
  //     var filterData = this.searchCondList.all.filter(obj => obj.value == element.value);
  //     element.label = filterData.length > 0 ? filterData[0].label : '';
  //   });
  //   return xarry;
  // }

  //更多查询条件label change
  public changesearchfield(index: any): void {
    this.searchVal[index].value = '';
    this.searchVal[index].condition = 'EQUAL';
    if (this.searchVal[index].field_no) {
      var fieldArray = this.searchlableList.filter(obj => obj.value == this.searchVal[index].field_no);
      if (fieldArray.length > 0) {
        this.searchVal[index].lable_type = fieldArray[0].lable_type;
        this.searchVal[index].table_no = fieldArray[0].table_no;
        this.searchVal[index].more01 = fieldArray[0].more01;
        this.searchVal[index].aae005 = fieldArray[0].aae005;
        this.searchVal[index].aal005 = fieldArray[0].aal005;
      }
      if (this.searchVal[index].lable_type == '1' || (this.searchVal[index].lable_type == '3' && this.searchVal[index].aae005 != 'NUMBER')) {
        this.searchVal[index].cond_type = 'text';
      } else if (this.searchVal[index].lable_type == '2') {
        this.searchVal[index].cond_type = 'select';
        this.searchVal[index].value = [];
        if (this.searchVal[index].aal005 === null) {
          this.searchVal[index].more01 = [];
        }
      } else if ((this.searchVal[index].lable_type == '3' && this.searchVal[index].aae005 == 'NUMBER') ||
        this.searchVal[index].lable_type == '4' || this.searchVal[index].lable_type == '5' ||this.searchVal[index].lable_type == '7'||this.searchVal[index].lable_type == '8') {
        this.searchVal[index].cond_type = 'number';
      } else {
        this.searchVal[index].cond_type = 'all';
      }
    } else {
      this.searchVal[index].lable_type = '3';
      this.searchVal[index].table_no = '';
      this.searchVal[index].more01 = [];
      this.searchVal[index].cond_type = 'all';
    }
    this.getsearchParam();
  }


  /**
   * 添加更多查询条件
   */
  // public addMore(): void {
  //   var nowLen = this.searchVal.length;
  //   for (let index = nowLen; index < (nowLen + 3); index++) {
  //     const newobj = Object.assign({}, this.searchValObj);
  //     this.searchVal[index] = newobj;
  //   }
  //   console.log('添加更多', this.searchVal);
  // }

  /**
   * 添加更多查询条件
   */
  // public minusMore(): void {
  //   var newLen = this.searchVal.length - 3;
  //   if (newLen > 1) {
  //     this.searchVal.splice(newLen, 3);
  //   }
  //   console.log('添加更多', this.searchVal);
  // }

  /**
   * 查询事件
   *
   * @param {boolean} [isAsync=false] 是否同步处理
   * @memberof DocumentOrderComponent
   */
  // public searchData(isAsync: boolean = false): void {
  //   // sessionStorage.setItem(this.programId, JSON.stringify({searchVal: this.searchVal, pageNumber: 1, pageSize: 10}));
  //   this.getsearchParam();
  //   this.onSearch.emit(isAsync);height: 40px;
  // }

  getsearchParam(): any {
    this.searchParam.condition.items = [];
    for (const obj of this.searchVal) {
      var valData = [];
      if (obj.field_no != '') {
        if (obj.condition == 'IS_BLANK' || obj.condition == 'NOT_BLANK') {
          this.searchParam.condition.items.push({
            table_no: obj.table_no,
            name: obj.field_no,
            operator: obj.condition,
            value: valData,
            type: obj.lable_type
          });
        } else {
          if (isString(obj.value) && obj.value != '') {
            valData = obj.value.split(new RegExp('[,|\x22|，]+'));
          } else if (isDate(obj.value) && obj.value != null) {
            if (obj.lable_type == '4') {
              var valdate = (new DatePipe('zh_tw')).transform(obj.value, 'yyyy/MM/dd');
            } else if(obj.lable_type == '7'){
              var valdate = (new DatePipe('zh_tw')).transform(obj.value, 'yyyy/MM');
            } else {
              var valdate = (new DatePipe('zh_tw')).transform(obj.value, 'yyyy/MM/dd HH:MM:ss');
            }
            valData = [valdate];
          } else if (isArray(obj.value) && obj.value.length > 0) {
            for (let index = 0; index < obj.value.length; index++) {
              if(obj.lable_type == '8'){
                valData[index] = (new DatePipe('zh_tw')).transform(obj.value[index], 'yyyy/MM/dd');
              } else {
                valData[index] = obj.value[index];
              }
            }
          }
          if (valData.length > 0) {
            this.searchParam.condition.items.push({
              table_no: obj.table_no,
              name: obj.field_no,
              operator: obj.condition,
              value: valData,
              type: obj.lable_type
            });
          }
        }
      }
    }
  }

  /**
   * 常用查询条件
   */
  public changecomCond(xVal): void {
    // console.log('常用查询', xVal);
    // if (xVal != 'save' && xVal != 'edit') { // 切换查询条件
    //   var fieldArray = this.gcomCondsList.filter(obj => obj.value == xVal);
    //   if (fieldArray.length > 0) {
    //     var aan004str = JSON.parse(fieldArray[0].aan004);
    //     if (aan004str.moresel) {
    //       this.searchVal = aan004str.moresel;
    //     }
    //     this.searchParam.glikesel = aan004str.likesel || '';
    //   }
    //   this.searchData();
    // }
  }


  /**
   * 保存常用条件
   */
  // tslint:disable-next-line: typedef
  public saveComCond(xState) {
    // if (xState == 'save') {
    //   var aan004str = JSON.stringify({
    //     moresel: this.searchVal || [],
    //     likesel: this.searchParam.glikesel
    //   });
    // }

  }
  // tslint:disable-next-line: typedef
  handleDetermine(): void {
    // var aan004str = JSON.stringify({
    //   moresel: this.searchVal || [],
    //   likesel: this.searchParam.glikesel
    // });
    // this.aan004 = aan004str;
    // this.modalSubject.triggerOk();
  }

  /**
   * 清除
   *
   * @memberof DocumentOrderComponent
   */
  // public resetForm(): void {
  //   // 查詢條件欄位初始化
  //   this.searchParam = {
  //     pageable: true,
  //     // pageSize: 10,
  //     // pageNumber: 1,
  //     glikesel: '',
  //     gcomConds: '',
  //     orderfields: [],
  //     condition: {
  //       joinOperator: 'AND',
  //       items: []
  //     }
  //   };
  //   this.searchVal.forEach(obj => {
  //     obj.table_no = this.searchValObj.table_no;
  //     obj.field_no = this.searchValObj.field_no;
  //     obj.condition = this.searchValObj.condition;
  //     obj.value = this.searchValObj.value;
  //     obj.lable_type = this.searchValObj.lable_type;
  //     obj.more01 = this.searchValObj.more01;
  //     obj.cond_type = this.searchValObj.cond_type;
  //     obj.aae005 = this.searchValObj.aae005;

  //   });
  //   console.log('cansh', this.searchVal);
  //   // for (let index = 0; index < this.searchValNum; index++) {
  //   //   this.searchVal[index] = newobj;
  //   // }
  // }

  //开窗 ==  begin  ==
  // public openParamModal($event: MouseEvent, isAsync: boolean = false, index): void {
  //   $event.preventDefault();
  //   this.nowOpenIndex = index;
  //   this.openModal.emit(isAsync);
  // }

  // public openSelect(isAsync: boolean = false, index): void {
  //   if (this.searchVal[index].aal005 === null) {
  //     this.nowOpenIndex = index;
  //     this.OptionList.emit(isAsync);
  //   }
  // }


  // onKeyDown(event): void {
  //   if (event.keyCode === 13) {
  //     this.searchData();
  //   }
  // }


  // 开窗 ==  end  ==

  // ngAfterViewInit(): void {
  //   this.onReady.emit(true);
  // }
}
