/**
 * 处理历程封装
 * paramInfo {program_no: '', process_no: '', document_no: ''  } // 作业编号 签核流程编号 数据单号
 */
import { Component, Input, OnInit, SimpleChanges,ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FxDapHttpClient } from 'app/implementation/shared/fx-http-client';
// @Component({
//   selector: 'app-process-info',
//   template: `<div dw-row [dwJustify]="'start'" class="fx_listInfo fx_timeline">
//               <dw-timeline dwMode="custom">
//                 <dw-timeline-item *ngFor="let obj of processList" dwPosition="left">
//                   <p class="fx_timeline_title">{{ obj.acu002 + (obj.acu002_n1 ? ('(' + obj.acu002_n1 + ')') : '') }}</p>
//                   <div class="fx_timeline_list" [ngClass]="{'fx_timeline_list_nowarp': i <= 3}" *ngFor="let val of obj.detailList;let i = index">					
//                     <p> <strong>{{val.label}}</strong> {{val.value}}</p>
//                   </div>
//                 </dw-timeline-item>
//               </dw-timeline>
//              </div>`,
//   styles: [` 
//   .fx_listInfo.fx_timeline {
//     padding: 20px 0 0 40px !important;
//   } 

//   .fx_timeline_title {
//     font-weight: 600;
//     color: #1890ff;
//   }

//   .fx_timeline_list_nowarp {
//     display: inline-block;
//     width: 25%;
//   }
//   `]
// })

@Component({
  selector: 'app-process-info',
  template: `<div dw-row [dwJustify]="'start'" class="fx_listInfo fx_timeline">
              <dw-timeline dwMode="custom">
                <dw-timeline-item *ngFor="let obj of processList" [dwColor]="obj.acu004 == '1' ? 'gray' : obj.acu004 == '2' || obj.acu004 == '4' ? 'green' : 'red'" dwPosition="left">
                  <p *ngIf="obj.acu004 == '1'" class="fx_timeline_title3">{{obj.seq}} {{obj.ddate}} 待 {{obj.acu002_n1}} {{obj.acu003}}({{obj.acu003_n1}}) 审批</p>
                  <p *ngIf="obj.acu004 == '2' && obj.seq == '1'" class="fx_timeline_title3">{{obj.seq}} {{obj.ddate}} 由 {{obj.acu002_n1}} {{obj.acu003}}({{obj.acu003_n1}}) 发起审批</p>
                  <p *ngIf="obj.acu004 == '2' && obj.seq != '1'" class="fx_timeline_title3">{{obj.seq}} {{obj.ddate}} 由 {{obj.acu002_n1}} {{obj.acu003}}({{obj.acu003_n1}}) 审批同意，审批意见：{{obj.acu006}}</p>
                  <p *ngIf="obj.acu004 == '3'" class="fx_timeline_title3">{{obj.seq}} {{obj.ddate}} 由 {{obj.acu002_n1}} {{obj.acu003}}({{obj.acu003_n1}}) 退回，审批意见：{{obj.acu006}}</p>
                  <p *ngIf="obj.acu004 == '4'" class="fx_timeline_title3">{{obj.seq}} {{obj.ddate}} 由 {{obj.acu002_n1}} {{obj.acu003}}({{obj.acu003_n1}}) 加签</p>
                </dw-timeline-item>
              </dw-timeline>
             </div>`,
  styles: [` 
  .fx_listInfo.fx_timeline {
    padding: 20px 0 0 40px !important;
  } 
  .ant-timeline-item-last .ant-timeline-item-content {
    min-height : 36px;
  }
  .ant-timeline-item-content {
    margin: 0 0 -6px 18px;
  }

  .fx_timeline_title {
    font-size: 12px;
    color: #1890ff;
  }
  .fx_timeline_title1 {
    font-size: 12px;
    color: green;
  }
  .fx_timeline_title2 {
    font-size: 12px;
    color: red;
  }
  .fx_timeline_title3 {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.85);
  }

  .fx_timeline_list_nowarp {
    display: inline-block;
    width: 25%;
  }
  `],
  encapsulation: ViewEncapsulation.None,
})
export class ProcessInfoComponent implements OnInit {

  public _paramInfo: any;
  public processList: any;
  @Input() set paramInfo(paramInfo: any) {
    this._paramInfo = paramInfo;
  }
  get paramInfo(): any { return this._paramInfo; }

  public morelog_datas: any;
  showInfo = false;

  constructor(
    public translateService: TranslateService,
    public http: FxDapHttpClient) {
  }

  ngOnInit(): void {
    this.dataInit(); //获取初始数据
  }


  change(value: boolean): void {
    console.log(value);
  }

  /**
   * dataInit获取历程信息
   */
  public dataInit(): void {
    // 签核流程编号 数据单号
    if (!this._paramInfo.process_no || !this._paramInfo.document_no) {
      return;
    }
    this.http.post<any>('A/IOaCommonService/inquiryProcess', { param: this._paramInfo }).subscribe(result => {
      this.processList = result.data;
      // this.processList.forEach(obj => {
      //   obj.detailList = [
      //     { label: this.translateService.instant('comm-处理人') + ':', value: obj.acu003 + (obj.acu003_n1 ? ('(' + obj.acu003_n1 + ')') : '') },
      //     { label: this.translateService.instant('comm-处理状态') + ':', value: obj.acu004_n1 },
      //     { label: this.translateService.instant('comm-建立日期') + ':', value: obj.acuddate },
      //     { label: this.translateService.instant('comm-处理日期') + ':', value: obj.acu005 },
      //     { label: this.translateService.instant('comm-处理意见') + ':', value: obj.acu006 },
      //   ];
      // });
      for (var m = 0;m < this.processList.length;m++) {
        this.processList[m].detailList = [
          { label: this.translateService.instant('comm-处理人') + ':', value: this.processList[m].acu003 + (this.processList[m].acu003_n1 ? ('(' + this.processList[m].acu003_n1 + ')') : '') },
          { label: this.translateService.instant('comm-处理状态') + ':', value: this.processList[m].acu004_n1 },
          { label: this.translateService.instant('comm-建立日期') + ':', value: this.processList[m].acuddate },
          { label: this.translateService.instant('comm-处理日期') + ':', value: this.processList[m].acu005 },
          { label: this.translateService.instant('comm-处理意见') + ':', value: this.processList[m].acu006 },
        ];
        if (m == 0) {
          this.processList[m].ddate = this.processList[m].acu005 ? this.processList[m].acu005 : this.processList[m].acuddate;
        } else {
          if (this.processList[m].acu004 == '1') {
            this.processList[m].ddate = this.processList[m - 1].ddate;
          } else {
            this.processList[m].ddate = this.processList[m].acu005 ? this.processList[m].acu005 : this.processList[m].acuddate;
          }
        }
      }
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['infoOption'] !== undefined) {
      this.dataInit();
    }
  }

} 